const OFFER_OUT_OF_STOCK = 'OUT_OF_STOCK';
const OFFER_AVAILABLE = 'AVAILABLE';
const OFFER_UNAVAILABLE = 'UNAVAILABLE';
const SHOP_LIST_BY_ONE_PRODUCT = 'shopListByOneProduct';

const BETTER = 'better';
const ENTRETENIMIENTO = 'entretenimiento';
const EONLINE = 'eonline';
const GLOBALCITIZEN = 'globalcitizen';
const MSNBC = 'msnbc';
const NEWS = 'news';
const NOTICIAS = 'noticias';
const SELECT_CNBC = 'select_cnbc';
const SELECT_NEWS = 'select_news';
const SHOWS = 'shows';
const TELEMUNDO = 'telemundo';
const THINK = 'think';
const TODAY = 'today';

const SHARE_LINK_COPIED_TEXT = 'Share link copied';
const LINK_COPIED_TEXT = 'Copied';
const EMBED_COPIED_TEXT = 'Copied';


const BASE_URL = 'https://nodeassets.nbcnews.com/assets/fonts/shared';
const FONTFACE_DEFAULT = `${BASE_URL}/fontface.03d5c661ecce53da56e9e6db8475fbf5.css`;
const FONTFACE_OPENSANS = `${BASE_URL}/fontface-opensans.5593150ab83698a86a9a0cd5477d3b26.css`;
const FONTFACE_LATO = `${BASE_URL}/fontface-lato.3a30eaa3de132b99a1fe90236087c713.css`;
const FONTFACE_TODAY = `${BASE_URL}/fontface-today.0823aa275da4e8a54ebd57202ed2ef22.css`;
const FONTFACE_OSWALD = `${BASE_URL}/fontface-oswald.b29738af9afe598512d21970d0a7b3c1.css`;


const FONTFACE_BY_VERTICAL = {
  eonline: [FONTFACE_DEFAULT, FONTFACE_OPENSANS],
  select: [FONTFACE_LATO],
  today: [FONTFACE_TODAY],
  default: [FONTFACE_DEFAULT, FONTFACE_OSWALD],
};

const JW_PLAYER_LIBRARY_URL = 'https://nodeassets.nbcnews.com/jwplayer/jwplayer-8.30.1/jwplayer.js';


const AUTH_FIELDS_TO_INCLUDE = [
  'email',
  'password',
  'brand',
  'source',
  'profile',
  'first_name',
  'last_name',
];

module.exports = {
  OFFER_OUT_OF_STOCK,
  OFFER_AVAILABLE,
  OFFER_UNAVAILABLE,
  SHOP_LIST_BY_ONE_PRODUCT,
  BETTER,
  ENTRETENIMIENTO,
  EONLINE,
  GLOBALCITIZEN,
  MSNBC,
  NEWS,
  NOTICIAS,
  SELECT_CNBC,
  SELECT_NEWS,
  SHOWS,
  TELEMUNDO,
  THINK,
  TODAY,
  SHARE_LINK_COPIED_TEXT,
  LINK_COPIED_TEXT,
  EMBED_COPIED_TEXT,
  FONTFACE_DEFAULT,
  FONTFACE_OPENSANS,
  FONTFACE_LATO,
  FONTFACE_TODAY,
  FONTFACE_OSWALD,
  FONTFACE_BY_VERTICAL,
  JW_PLAYER_LIBRARY_URL,
  AUTH_FIELDS_TO_INCLUDE,
};
