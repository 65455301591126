/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { useBedrockRegistration } from 'store/bedrockRegistration';
import { Button } from 'components/Button';
import {
  Formik, Field, ErrorMessage,
} from 'formik';
import { oneTimeCodeSchema } from '../validationSchema';

import globalStyles from '../styles.module.scss';

/**
 * VerifyOTCScreen component for handling log in with a one time code.
 * @param {object} props - The component props.
 * @param {string} props.userEmail - The email address associated with the user.
 * @param {Function} props.setScreen - Function to set the screen state.
 * @returns {React.ReactElement} The rendered component.
 */
const VerifyOTCScreen = ({ userEmail, setScreen }) => {
  const user = useBedrockRegistration((state) => state.user);
  const requestOneTimeCode = useBedrockRegistration((state) => state.requestOneTimeCode);
  const verifyOneTimeCode = useBedrockRegistration((state) => state.verifyOneTimeCode);
  const emailToRender = userEmail ?? user.email;
  /**
   * Handles form submission
   * @param {object} values - The component props.
   * @param {object} values.oneTimeCode - The one time code sent to the user.
   */
  const onSubmit = (values) => {
    verifyOneTimeCode(values.oneTimeCode).then((result) => {
      // update the screen value based on the response
      if (result) setScreen(result);
    });
  };

  /**
   * Handles resend code button click
   */
  const resendCode = () => {
    requestOneTimeCode().then((result) => {
      if (result) setScreen(result);
    });
  };

  return (
    <>
      <h2 data-testid="account-verify-email">Log in with code</h2>
      <p>
        Enter the one-time code we sent to
        <br />
        <br />
        <span data-testid="verifyOTC-userEmail">{emailToRender}</span>
        <br />
        <br />
        If you couldn&apos;t find our email, please check your spam folder
      </p>
      <Formik
        initialValues={{ oneTimeCode: '' }}
        validationSchema={oneTimeCodeSchema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          errors,
        }) => (
          <form
            onSubmit={handleSubmit}
            data-testid="account-password"
          >
            <Field
              name="oneTimeCode"
              type="input"
              placeholder=""
              data-testid="oneTimeCode-input-field"
              className={errors.oneTimeCode ? globalStyles.errorInput : ''}
            />
            <ErrorMessage name="oneTimeCode" component="div" className={globalStyles.errorMessage} />
            <Button
              displayType="submit"
              title="Log in"
              onClick={handleSubmit}
            />
          </form>

        )}
      </Formik>
      <Button
        title="Send code again"
        onClick={resendCode}
      />
    </>

  );
};

VerifyOTCScreen.propTypes = {
  setScreen: PropTypes.func.isRequired,
  userEmail: PropTypes.string,
};

VerifyOTCScreen.defaultProps = {
  userEmail: null,
};

export { VerifyOTCScreen };
