import {
    Analytics,
    eventLogger,
    getDefaultContentData,
    readCcwContentDataFromStorage,
    readContentDataFromStorage,
    readResponsiveAdsProductKeysFromStorage,
    readSponsoredProductsFromStorage,
    writeContentDataByTypeToStorage,
    writeContentDataToStorage
} from "common/lib/utils";

import { UscCategory } from "common/lib/interfaces/product";
import { ContentType } from "common/lib/constants";

import { CategoryData, ShoppableContentLoad } from "../types";
import {
    getShoppableElementsAndLinks,
    getSponsorshipBannerElementsAndLinks,
    hasShoppableElementsAndLinks,
    hasSponsorshipElementsAndLinks,
    hasListacleElements,
    SPONSORSHIP_ATTRIBUTE,
    CITRUS_AD_ID,
    getSponsorshipCitrusElementsAndLinks,
    hasBannerAdsElements,
    getBannerAdsElements,
    BANNER_AD_ID
} from "./dom";
import { parseQuery, PDP_LOCATION_HASH_PREFIX } from "./url";
import { ReportService } from "./citrusReporter";
import { productsService } from "../services/ProductsService";

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        mParticle: any;
    }
}

const reportService = new ReportService();
let shoppableElements: NodeListOf<HTMLElement>;
let sponsorshipBannerElements: NodeListOf<HTMLElement>;
let bannerAdsElements: NodeListOf<HTMLElement>;

const PROMISE_POLYFILL = "src_promise-polyfill_js";

const analyticsEnabled = (): boolean => !!window.mParticle;

const getContentData = (productKey: string) => {
    const contextualKeys = readCcwContentDataFromStorage();
    const contextualProductData = contextualKeys.find(
        ({ key }) => key === productKey
    );

    if (contextualProductData) {
        return {
            contentType: ContentType.CCW,
            contentName: document.title,
            score: contextualProductData.score ?? null
        };
    }

    const productKeys = readResponsiveAdsProductKeysFromStorage();
    if (productKeys.includes(productKey)) {
        return readContentDataFromStorage();
    }

    const productIds: string[] = readSponsoredProductsFromStorage();
    if (
        productIds.some(productId => {
            const productData = productsService.getProductData(productKey);
            return productData?.productId === productId;
        })
    ) {
        writeContentDataToStorage({
            type: ContentType.AD,
            name: document.title
        });
        return {
            contentType: ContentType.AD,
            contentName: document.title
        };
    }

    return getDefaultContentData();
};

const getCategoryData = (productCategories: UscCategory[]): CategoryData => {
    if (!window.uscWidget.config?.getDefaultContentData) {
        return {};
    }

    const { categories } = window.uscWidget.config.getDefaultContentData();

    if (!categories?.length || !productCategories?.length) return {};

    const categoryData: CategoryData = {};

    productCategories.forEach(productCategory => {
        const category = categories.find(
            ({ key }) => productCategory.key === key
        );

        if (category?.entityName) {
            categoryData.entityReference = category.entityName;
        }

        if (category?.type) {
            categoryData.typeReference = category.type;
        }
    });

    return categoryData;
};

const getShoppableCategoriesData = () => {
    if (!window.uscWidget.config?.getDefaultContentData) {
        return {};
    }

    const { categories } = window.uscWidget.config.getDefaultContentData();
    if (!categories?.length) {
        return {};
    }

    const categoryData: CategoryData = {};
    const category = categories.find(({ entityName, type }) => {
        return entityName || type;
    });

    if (category?.entityName) {
        categoryData.entityReference = category.entityName;
    }

    if (category?.type) {
        categoryData.typeReference = category.type;
    }

    return categoryData;
};

export const getProductAttributes = (
    productKey: string,
    channelKey: string,
    analyticsCustomAttributes?: Record<string, string | boolean>
) => {
    const productData = productsService.getProductData(productKey);
    const content = getContentData(productKey);
    const categoryData = getCategoryData(productData?.categories || []);
    const utmParams = Analytics.getUtmParams();

    const miraklCategories = Analytics.getMiraklProductCategoriesAttributes(
        productData?.categories
    );

    if (!productData) {
        return null;
    }

    const productInfoAttributes = {
        productId: `${channelKey}:${productKey}`,
        productName: productData?.name || null,
        sellerId: channelKey,
        sellerName: productData?.sellerName || null,
        sponsorName: productData?.brand || null
    };

    const { sponsorName, ...restProductInfo } = productInfoAttributes;

    const productAttributes = {
        ...restProductInfo,
        brand: productData?.brand || null,
        isBackordered: productData?.isBackordered,
        isDelayedShipping: productData?.isDelayedShipping,
        list: "Editorial",
        productIdCommerce: productData.productId,
        ...miraklCategories,
        ...content,
        ...categoryData,
        ...analyticsCustomAttributes,
        ...utmParams,
        ...Analytics.getShoppableMomentId()
    };

    return { productInfoAttributes, productAttributes };
};

// previousLocationPath is needed for the cases when usc widget is used on Single Page Applications.
// Whenever SPA calls rescan method previousLocationPath is checked against
// current location pathname. If it differs we assume that analyticsOnPageLoad
// event has to be triggered.
let previousLocationPath = window.location.pathname;

export const analyticsOnProductClicked = ({
    productKey,
    channelKey,
    citrusAdId,
    analyticsDataAttributes,
    sourceElement
}: {
    productKey: string;
    channelKey: string;
    citrusAdId?: string;
    analyticsDataAttributes?: string;
    sourceElement?: string;
}): void => {
    const contentData = readContentDataFromStorage();
    const analyticsCustomAttributes = analyticsDataAttributes
        ? Analytics.parseCustomAttributes(analyticsDataAttributes)
        : undefined;

    const attributes = getProductAttributes(productKey, channelKey, {
        ...analyticsCustomAttributes,
        isBackInStock: !!window.uscWidget.config.backInStock,
        sourceElement: sourceElement || contentData.contentName
    });

    if (citrusAdId) {
        eventLogger.logSponsorClickedEvent({
            ...Analytics.getSponsorEventAttributes(
                analyticsCustomAttributes,
                attributes?.productInfoAttributes
            ),
            contentName: contentData.contentName
        });
    }

    if (attributes?.productAttributes) {
        eventLogger.logProductClickedEvent(attributes.productAttributes);
    }
};

const sendShoppableElementClick = (e: MouseEvent) => {
    if (!window.mParticle) {
        console.log("USC: [mParticle] Product clicked event not sent.");
        return;
    }
    try {
        const target = e.target as HTMLElement;
        const currTarget = e.currentTarget as HTMLElement;
        const productKey =
            currTarget.dataset.productKey || target.dataset.productKey || "";
        const sellerId =
            currTarget.dataset.productChannelKey ||
            target.dataset.productChannelKey ||
            "";
        const analyticsDataAttributes =
            currTarget.dataset.analyticsCustomAttributes ||
            target.dataset.analyticsCustomAttributes;
        let citrusAdId =
            currTarget.dataset.productAdId || target.dataset.productAdId;

        if (!citrusAdId) {
            const bannerElem = target.closest<HTMLElement>(
                `[data-${BANNER_AD_ID}]`
            );

            if (bannerElem) {
                citrusAdId = bannerElem.dataset.bannerAdId;
            }
        }

        analyticsOnProductClicked({
            productKey,
            channelKey: sellerId,
            citrusAdId,
            analyticsDataAttributes,
            sourceElement: target.nodeName.toLowerCase()
        });
    } catch (error) {
        console.error(
            "USC: [mParticle] Product clicked error sending event.",
            error
        );
    }
};

const sendSponsorshipElementClick = (e: MouseEvent) => {
    if (!window.mParticle) {
        console.log("USC: [mParticle] Sponsor clicked event not sent.");
        return;
    }
    try {
        const target = e.target as HTMLElement;
        const currTarget = e.currentTarget as HTMLElement;
        const analyticsDataAttributes =
            currTarget.dataset.analyticsCustomAttributes ||
            target.dataset.analyticsCustomAttributes;

        const analyticsCustomAttributes = analyticsDataAttributes
            ? Analytics.parseCustomAttributes(analyticsDataAttributes)
            : undefined;

        eventLogger.logSponsorClickedEvent(
            Analytics.getSponsorEventAttributes(analyticsCustomAttributes)
        );
    } catch (error) {
        console.error(
            "USC: [mParticle] Sponsor clicked error sending event.",
            error
        );
    }
};

const onShoppableElementClick = (e: MouseEvent) => {
    sendShoppableElementClick(e);
    reportService.sendClick(e);
};

function getProductKeyAndChannelKey(element: HTMLElement) {
    let productKey;
    let productChannelKey;

    try {
        if (element.dataset.productKey && element.dataset.productChannelKey) {
            productKey = element.dataset.productKey;
            productChannelKey = element.dataset.productChannelKey;
        } else {
            const queryString = (element as HTMLLinkElement).href.split(
                `${PDP_LOCATION_HASH_PREFIX}?`
            )[1];
            const params = parseQuery<{ [key: string]: string }>(queryString);

            productKey = params["product-key"];
            productChannelKey = params["channel-key"];
        }
    } catch (err) {
        productKey = "";
        productChannelKey = "";
    }
    return { productKey, productChannelKey };
}

export function getBannerAdsAttributes() {
    return {
        contentType: ContentType.AD,
        contentName: document.title
    };
}

export const logProductViewed = ({
    productKey,
    channelKey,
    hasSponsorshipAttribute = false,
    bannerAdId,
    analyticsCustomAttributes,
    citrusAdId,
    elementKey
}: {
    productKey: string;
    channelKey: string;
    hasSponsorshipAttribute?: boolean;
    bannerAdId?: string;
    analyticsCustomAttributes?: Record<string, string>;
    citrusAdId?: string;
    elementKey?: string;
}) => {
    const attributes = getProductAttributes(
        productKey,
        channelKey,
        analyticsCustomAttributes
    );

    if (citrusAdId) {
        const sponsorEventAttributes = Analytics.getSponsorEventAttributes(
            analyticsCustomAttributes,
            attributes?.productInfoAttributes
        );

        eventLogger.logIABImpressionEvent(
            {
                citrusAdId,
                ...sponsorEventAttributes
            },
            ["citrusAdId"]
        );

        eventLogger.logSponsorViewedEvent(
            {
                citrusAdId,
                ...sponsorEventAttributes
            },
            ["citrusAdId"]
        );
    }

    if (bannerAdId) {
        eventLogger.logIABImpressionEvent(
            { ...getBannerAdsAttributes(), bannerAdId },
            ["bannerAdId"]
        );
        eventLogger.logSponsorViewedEvent(
            { ...getBannerAdsAttributes(), bannerAdId },
            ["bannerAdId"]
        );
    }

    if (hasSponsorshipAttribute) {
        eventLogger.logSponsorViewedEvent(
            {
                key: elementKey,
                ...Analytics.getSponsorEventAttributes(
                    analyticsCustomAttributes,
                    attributes?.productInfoAttributes
                )
            },
            ["key"]
        );
    } else if (attributes?.productAttributes) {
        eventLogger.logProductViewedEvent(attributes.productAttributes);
    }
};

const sendEventIfInViewport = (entries: IntersectionObserverEntry[]): void => {
    if (!window.mParticle) {
        console.log("USC: [mParticle] Product viewed event not sent.");
        return;
    }
    try {
        entries?.forEach(entry => {
            const element = entry.target as HTMLElement;
            const { productKey, productChannelKey: channelKey } =
                getProductKeyAndChannelKey(element);

            if (entry.isIntersecting) {
                const analyticsDataAttributes =
                    element.dataset.analyticsCustomAttributes;
                const analyticsCustomAttributes = analyticsDataAttributes
                    ? Analytics.parseCustomAttributes(analyticsDataAttributes)
                    : undefined;
                const citrusAdId = element.dataset.productAdId;
                const elementKey = element.dataset.key;
                const hasSponsorshipAttribute = element.hasAttribute(
                    `data-${SPONSORSHIP_ATTRIBUTE}`
                );
                const { bannerAdId } = element.dataset;

                logProductViewed({
                    productKey,
                    channelKey,
                    analyticsCustomAttributes,
                    citrusAdId,
                    hasSponsorshipAttribute,
                    elementKey,
                    bannerAdId
                });
            }
        });
    } catch (e) {
        console.error("USC: [mParticle] viewPort error sending event.", e);
    }
};

const intersectionObserver = new IntersectionObserver(
    (entries: IntersectionObserverEntry[]) => {
        sendEventIfInViewport(entries);
        reportService.sendImpression(entries);
    },
    {
        threshold: 0.5
    }
);

const mutationObserver = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
        const element = mutation.target as HTMLElement;
        const style = getComputedStyle(element);
        if (style.visibility !== "visible") {
            intersectionObserver.unobserve(element);
        } else {
            intersectionObserver.observe(element);
        }
    });
});

const logPageLoadEvent = (): void => {
    const utmParams = Analytics.getUtmParams();
    const attributes = {
        ...utmParams,
        ...Analytics.getShoppableMomentId()
    };
    eventLogger.logPageLoadEvent(attributes);
};

const analyticsOnPageLoad = (): void => {
    if (!analyticsEnabled()) {
        console.log("USC: [mParticle] Page loaded event not sent.");
        return;
    }

    const shoppableItems = getShoppableElementsAndLinks();
    if (shoppableItems.length) {
        logPageLoadEvent();
    }
};

const registerShoppableElementsAndLinks = async (networkBrand: string) => {
    shoppableElements = getShoppableElementsAndLinks();
    if (!shoppableElements.length) {
        return;
    }

    await productsService.fetchProductsByHTMLElement(
        shoppableElements,
        networkBrand
    );

    shoppableElements.forEach(element => {
        if (getComputedStyle(element).visibility === "visible") {
            intersectionObserver.observe(element);
        }
        mutationObserver.observe(element, {
            attributes: true
        });

        if (!element.closest(`[data-${BANNER_AD_ID}]`)) {
            element.addEventListener("click", onShoppableElementClick);
        }

        if (element.dataset.productKey && element.dataset.productChannelKey) {
            const customAnalyticsAttributesData = element.dataset
                .analyticsCustomAttributes
                ? Analytics.parseCustomAttributes(
                      element.dataset.analyticsCustomAttributes
                  )
                : undefined;

            const attributes = getProductAttributes(
                element.dataset.productKey,
                element.dataset.productChannelKey,
                customAnalyticsAttributesData
            );

            if (attributes?.productAttributes) {
                eventLogger.logProductLoadedEvent(attributes.productAttributes);
            }
        }
    });
};

function logSponsorshipBannerImpression(element: HTMLElement, key: number) {
    // eslint-disable-next-line no-param-reassign
    element.dataset.key = key.toString();
    const analyticsCustomAttributes = element.dataset.analyticsCustomAttributes
        ? Analytics.parseCustomAttributes(
              element.dataset.analyticsCustomAttributes
          )
        : undefined;

    eventLogger.logSponsorImpressionEvent(
        {
            key,
            ...Analytics.getSponsorEventAttributes(analyticsCustomAttributes)
        },
        ["key"]
    );
}

function logBannerAdsImpression(element: HTMLElement) {
    const { bannerAdId } = element.dataset;
    eventLogger.logSponsorImpressionEvent(
        { citrusAdId: bannerAdId, ...getBannerAdsAttributes() },
        ["citrusAdId"]
    );
}

const registerSponsorshipBannerElementsAndLinks = () => {
    sponsorshipBannerElements = getSponsorshipBannerElementsAndLinks();
    if (!sponsorshipBannerElements.length) {
        return;
    }

    sponsorshipBannerElements.forEach((element, key) => {
        if (getComputedStyle(element).visibility === "visible") {
            intersectionObserver.observe(element);
            logSponsorshipBannerImpression(element, key);
        }
        mutationObserver.observe(element, {
            attributes: true
        });
        element.addEventListener("click", sendSponsorshipElementClick);
    });
};

const registerBannerAdsElements = () => {
    bannerAdsElements = getBannerAdsElements();

    if (!bannerAdsElements.length) {
        return;
    }

    bannerAdsElements.forEach(element => {
        intersectionObserver.observe(element);
        logBannerAdsImpression(element);
    });
};

function logSponsorshipCitrusImpression() {
    const sponsorshipCitrusElements = getSponsorshipCitrusElementsAndLinks();

    sponsorshipCitrusElements.forEach(element => {
        if (getComputedStyle(element).visibility === "visible") {
            const analyticsCustomAttributes = element.dataset
                .analyticsCustomAttributes
                ? Analytics.parseCustomAttributes(
                      element.dataset.analyticsCustomAttributes
                  )
                : undefined;
            const { productKey, productChannelKey } =
                getProductKeyAndChannelKey(element);
            const attributes = getProductAttributes(
                productKey,
                productChannelKey,
                analyticsCustomAttributes
            );
            const citrusAdId = element.hasAttribute(`data-${CITRUS_AD_ID}`);

            eventLogger.logSponsorImpressionEvent(
                {
                    citrusAdId,
                    ...Analytics.getSponsorEventAttributes(
                        analyticsCustomAttributes,
                        attributes?.productInfoAttributes
                    )
                },
                ["citrusAdId"]
            );
        }
    });
}

function writeSponsoredProductIdsToContentStorage() {
    const sponsoredElements = getSponsorshipCitrusElementsAndLinks();
    const productIds = Array.from(sponsoredElements)
        .map(sponsoredElement => {
            const { productKey, productChannelKey } =
                getProductKeyAndChannelKey(sponsoredElement);
            const productAttributes = getProductAttributes(
                productKey,
                productChannelKey
            )?.productAttributes;

            return productAttributes?.productIdCommerce || "";
        })
        .filter(productId => productId)
        .join(",");

    writeContentDataByTypeToStorage({
        type: ContentType.SPONSORED_PRODUCTS,
        products: productIds
    });
}

export const analyticsOnShoppableContentLoad = (
    data: ShoppableContentLoad | undefined | null
): void => {
    if (!analyticsEnabled()) {
        console.log("USC: [mParticle] shoppableContentLoad not sent.");
        return;
    }

    if (data?.content) {
        logPageLoadEvent();

        const { type, name, ...restData } = data.content;
        const utmParams = Analytics.getUtmParams();
        const attributes = {
            contentType: type,
            contentName: name,
            ...restData,
            ...data.adDetails,
            ...utmParams,
            ...Analytics.getShoppableMomentId()
        };
        eventLogger.logShoppableContentLoadedEvent(attributes, [
            "pageIndex",
            "products"
        ]);
    }
};

export const initUSCAnalytics = async (
    networkBrand?: string
): Promise<void> => {
    Analytics.setReferringPage(document.referrer);

    if (!networkBrand) {
        console.error("networkBrand is a required param");
        return;
    }
    try {
        if (
            !hasShoppableElementsAndLinks() &&
            !hasSponsorshipElementsAndLinks() &&
            !hasBannerAdsElements()
        ) {
            return;
        }

        await registerShoppableElementsAndLinks(networkBrand);
        registerSponsorshipBannerElementsAndLinks();
        registerBannerAdsElements();

        logSponsorshipCitrusImpression();
        if (hasListacleElements()) {
            const { contentName, contentType, ...rest } =
                readContentDataFromStorage();
            const categoryData = getShoppableCategoriesData();
            analyticsOnShoppableContentLoad({
                content: {
                    name: contentName,
                    type: contentType,
                    ...categoryData,
                    ...rest
                }
            });
        }

        writeSponsoredProductIdsToContentStorage();
    } catch (e) {
        console.error(
            "USC: [mParticle] onClick and onScroll events are not set.",
            e
        );
    }
};

export const cleanupUSCAnalytics = (): void => {
    intersectionObserver.disconnect();
    mutationObserver.disconnect();
    shoppableElements?.forEach(shoppableElement => {
        shoppableElement.removeEventListener("click", onShoppableElementClick);
    });
    sponsorshipBannerElements?.forEach(sponsorshipElement => {
        sponsorshipElement.removeEventListener(
            "click",
            sendSponsorshipElementClick
        );
    });
};

export const rescanUSCAnalytics = async (
    networkBrand?: string
): Promise<void> => {
    if (previousLocationPath !== window.location.pathname) {
        previousLocationPath = window.location.pathname;
        eventLogger.resetSentEvents();
        reportService.resetReports();
        productsService.resetProducts();
        analyticsOnPageLoad();
    }

    cleanupUSCAnalytics();
    await initUSCAnalytics(networkBrand);
};

export const handleIsUscError = (error: Error) =>
    error &&
    error.stack &&
    (error.stack.includes("bootstrap.js") ||
        error.stack.includes("usc.bundle.js")) &&
    !error.message.includes(PROMISE_POLYFILL);

const buildErrorObject = (
    event: Event | string,
    source?: string,
    lineno?: number,
    colno?: number
): Error => ({
    message: typeof event === "string" ? event : event?.type,
    name: "Error",
    stack: `${source} at ${lineno}:${colno}`
});

const decorateErrorHandler = (fn: OnErrorEventHandler): OnErrorEventHandler => {
    return function (
        event: Event | string,
        source?: string,
        lineno?: number,
        colno?: number,
        error?: Error
    ) {
        try {
            const errorToLog =
                error || buildErrorObject(event, source, lineno, colno);

            const isUscError = handleIsUscError(errorToLog);

            if (isUscError) {
                Analytics.logError(errorToLog);
            }

            if (fn) {
                return fn(event, source, lineno, colno, error);
            }
        } catch (err) {
            console.log(
                "USC: Can not decorate error handler or build error object",
                err
            );
        }
        return () => {};
    };
};

type TDecorateUnhandledPromiseRejection = (
    this: WindowEventHandlers,
    ev: PromiseRejectionEvent
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => any;

const decorateUnhandledPromiseRejection = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fn: any
): TDecorateUnhandledPromiseRejection => {
    return function (event: PromiseRejectionEvent) {
        try {
            const errorToLog = event.reason ? event.reason : null;
            const isUscError = handleIsUscError(errorToLog);

            if (isUscError) {
                Analytics.logError(event.reason, event);
            }
            if (fn) return fn(event);
        } catch (err) {
            console.log("USC: can't decorate unhandled promice rejection", err);
        }
        return () => {};
    };
};

export const addErrorHandlers = () => {
    if (!analyticsEnabled()) return;

    try {
        window.onerror = decorateErrorHandler(window.onerror);
        window.onunhandledrejection = decorateUnhandledPromiseRejection(
            window.onunhandledrejection
        );
    } catch (err) {
        console.log("USC: can't catch unhandled error", err);
    }
};
