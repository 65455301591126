import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useBedrockRegistration } from 'store';

import { EmailScreen } from './Email';
import { LogInScreen } from './LogIn';
import { LoginSuccess } from './LogIn/LoginSuccess';
import { RegistrationScreen } from './Registration';
import { PasswordScreen } from './Password';
import { SocialRegistrationScreen } from './SocialRegistration';
import { VerifyOTCScreen } from './VerifyOneTimeCode';

import styles from './styles.module.scss';


/**
 * handles the screens for the login and registration flow
 *
 * @param {object} props - Component props.
 * @param {string} [props.entryTitle] - Title for the entry screen.
 * @param {string} [props.startScreen] - The screen to display, as an optional override from the default start of email screen
 * @returns {React.ReactElement} The rendered component.
 */
const AccountLoginRegistration = ({ entryTitle, startScreen }) => {
  const userId = useBedrockRegistration((state) => state.user.user_id);
  const [screen, setScreen] = useState(startScreen);
  const loading = useBedrockRegistration((state) => state.loading);

  /**
   * Returns the appropriate form view based on screen name status
   * @returns {React.Component} The screen component.
   */
  const getScreen = () => {
    // We are logged in, show the success screen only
    if (userId) {
      return LoginSuccess;
    }
    switch (screen) {
      case 'login':
        return LogInScreen;
      case 'registration':
        return RegistrationScreen;
      case 'loginSuccess':
      case 'registrationSuccess':
        return LoginSuccess;
      case 'password':
        return PasswordScreen;
      case 'socialRegistration':
        return SocialRegistrationScreen;
      case 'verifyOTC':
        return VerifyOTCScreen;
      default:
        return EmailScreen;
    }
  };

  const Screen = getScreen();

  /**
   * Manually sets the screen based on the selected value.
   * This is a temporary solution to bypass API calls.
   *
   * @param {object} e - The event object.
   */
  const manuallySetScreen = (e) => {
    setScreen(e.target.value);
  };

  if (loading) {
    return (
      <div className={styles.wrapper} data-testid="loading">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <>
        {userId ? null : (
          <div className={styles.temp}>
            <p>Manually set screen to bypass API calls</p>
            <select onChange={manuallySetScreen} value={screen}>
              <option value="default">Email</option>
              <option value="login">Login</option>
              <option value="loginSuccess">Login Success</option>
              <option value="registration">Registration</option>
              <option value="oneTimeCode">One Time Code</option>
              <option value="password">Password</option>
              <option value="socialRegistration">Social Registration</option>
            </select>
          </div>
        )}
        <Screen userId={userId} setScreen={setScreen} entryTitle={entryTitle} />
      </>
    </div>
  );
};

AccountLoginRegistration.propTypes = {
  entryTitle: PropTypes.string,
  startScreen: PropTypes.string,
};

AccountLoginRegistration.defaultProps = {
  entryTitle: 'Create your free TODAY account!',
  startScreen: 'default',
};

export { AccountLoginRegistration };
