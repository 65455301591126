var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable class-methods-use-this */
import hash from "object-hash";
import omit from "lodash-es/omit";
import { ANALYTICS_MESSAGES, CustomEventType, MParticleEvents } from "../constants";
import { Analytics } from ".";
function logOnlyOnce(eventName) {
    return function (target, propertyKey, descriptor) {
        const originalMethod = descriptor.value;
        // eslint-disable-next-line no-param-reassign
        descriptor.value = function (...args) {
            const eventAttributes = Object.assign(Object.assign({}, args[0]), { eventName });
            const hashedAttributes = hash(eventAttributes);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (!this.uniqueEventStorage.has(hashedAttributes)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.uniqueEventStorage.add(hashedAttributes);
                originalMethod.apply(this, args);
            }
        };
        return descriptor;
    };
}
function withCommonAttributes(eventName) {
    return function (target, propertyKey, descriptor) {
        const originalMethod = descriptor.value;
        // eslint-disable-next-line no-param-reassign
        descriptor.value = function (...args) {
            const eventAttributes = Object.assign(Object.assign({}, args[0]), Analytics.composeAttributesForEvent(eventName));
            // eslint-disable-next-line no-param-reassign
            args[0] = eventAttributes;
            originalMethod.apply(this, args);
        };
        return descriptor;
    };
}
const printEvent = (eventName, attrs) => {
    var _a, _b, _c, _d;
    try {
        const isTop = window.top === window;
        const whichWindow = isTop ? "[TOP]" : "[IFRAME]";
        const search = isTop
            ? (_a = document === null || document === void 0 ? void 0 : document.location) === null || _a === void 0 ? void 0 : _a.search
            : (_d = (_c = (_b = window.top) === null || _b === void 0 ? void 0 : _b.document) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.search;
        const params = new URLSearchParams(search);
        const uscDebug = params.get("uscDebug");
        if (uscDebug === "full") {
            console.trace(`${whichWindow}: ${eventName}`, JSON.stringify(attrs));
        }
    }
    catch (e) {
        console.log("Couldn't print event trace");
    }
};
class EventLogger {
    constructor() {
        this.uniqueEventStorage = new Set();
    }
    resetSentEvents() {
        this.uniqueEventStorage.clear();
    }
    logProductViewedEvent(attributes) {
        var _a, _b;
        const customFlags = {
            "Facebook.EventSourceUrl": window.location.href
        };
        printEvent(MParticleEvents.PRODUCT_VIEWED, attributes);
        (_a = this.getMParticle()) === null || _a === void 0 ? void 0 : _a.logEvent(MParticleEvents.PRODUCT_VIEWED, (_b = this.getMParticle()) === null || _b === void 0 ? void 0 : _b.EventType.Navigation, attributes, customFlags);
    }
    logProductClickedEvent(attributes) {
        var _a, _b;
        printEvent(MParticleEvents.PRODUCT_CLICKED, attributes);
        (_a = this.getMParticle()) === null || _a === void 0 ? void 0 : _a.logEvent(MParticleEvents.PRODUCT_CLICKED, (_b = this.getMParticle()) === null || _b === void 0 ? void 0 : _b.EventType.UserPreference, attributes);
    }
    logIABImpressionEvent(attributes, attributesToFilter) {
        var _a, _b;
        const filteredAttributes = omit(attributes, attributesToFilter);
        printEvent(MParticleEvents.IAB_IMPRESSION, attributes);
        (_a = this.getMParticle()) === null || _a === void 0 ? void 0 : _a.logEvent(MParticleEvents.IAB_IMPRESSION, (_b = this.getMParticle()) === null || _b === void 0 ? void 0 : _b.EventType.Navigation, filteredAttributes);
    }
    logSponsorClickedEvent(attributes) {
        var _a, _b;
        printEvent(MParticleEvents.SPONSOR_CLICKED, attributes);
        (_a = this.getMParticle()) === null || _a === void 0 ? void 0 : _a.logEvent(MParticleEvents.SPONSOR_CLICKED, (_b = this.getMParticle()) === null || _b === void 0 ? void 0 : _b.EventType.UserPreference, attributes);
    }
    logSponsorViewedEvent(attributes, attributesToFilter) {
        var _a, _b;
        const filteredAttributes = omit(attributes, attributesToFilter);
        printEvent(MParticleEvents.SPONSOR_VIEWED, attributes);
        (_a = this.getMParticle()) === null || _a === void 0 ? void 0 : _a.logEvent(MParticleEvents.SPONSOR_VIEWED, (_b = this.getMParticle()) === null || _b === void 0 ? void 0 : _b.EventType.Navigation, filteredAttributes);
    }
    logSponsorImpressionEvent(attributes, attributesToFilter) {
        var _a, _b;
        const filteredAttributes = omit(attributes, attributesToFilter);
        printEvent(MParticleEvents.SPONSOR_IMPRESSION, attributes);
        (_a = this.getMParticle()) === null || _a === void 0 ? void 0 : _a.logEvent(MParticleEvents.SPONSOR_IMPRESSION, (_b = this.getMParticle()) === null || _b === void 0 ? void 0 : _b.EventType.Navigation, filteredAttributes);
    }
    logPageLoadEvent(attributes) {
        var _a, _b;
        printEvent(MParticleEvents.SHOPPABLE_PAGE_LOAD, attributes);
        (_a = this.getMParticle()) === null || _a === void 0 ? void 0 : _a.logEvent(MParticleEvents.SHOPPABLE_PAGE_LOAD, (_b = this.getMParticle()) === null || _b === void 0 ? void 0 : _b.EventType.Navigation, attributes);
    }
    logShoppableContentLoadedEvent(attributes, attributesToFilter) {
        var _a, _b;
        const filteredAttributes = omit(attributes, attributesToFilter);
        printEvent(MParticleEvents.SHOPPABLE_CONTENT_LOAD, attributes);
        (_a = this.getMParticle()) === null || _a === void 0 ? void 0 : _a.logEvent(MParticleEvents.SHOPPABLE_CONTENT_LOAD, (_b = this.getMParticle()) === null || _b === void 0 ? void 0 : _b.EventType.Navigation, filteredAttributes);
    }
    logProductLoadedEvent(attributes) {
        var _a, _b;
        const customFlags = {
            "Facebook.EventSourceUrl": window.location.href
        };
        printEvent(MParticleEvents.PRODUCT_LOADED, attributes);
        (_a = this.getMParticle()) === null || _a === void 0 ? void 0 : _a.logEvent(MParticleEvents.PRODUCT_LOADED, (_b = this.getMParticle()) === null || _b === void 0 ? void 0 : _b.EventType.Navigation, attributes, customFlags);
    }
    logBackInStockEvent(attributes) {
        var _a;
        const customFlags = {
            "Facebook.EventSourceUrl": window.location.href
        };
        (_a = this.getMParticle()) === null || _a === void 0 ? void 0 : _a.logEvent(MParticleEvents.BACK_IN_STOCK, CustomEventType.UserPreference, attributes, customFlags);
    }
    logCartViewedEvent(attributes) {
        var _a;
        const customFlags = {
            "Facebook.EventSourceUrl": window.location.href
        };
        (_a = this.getMParticle()) === null || _a === void 0 ? void 0 : _a.logEvent(MParticleEvents.CART_VIEWED, CustomEventType.Navigation, attributes, customFlags);
    }
    logCheckoutMethodClickEvent(attributes) {
        var _a;
        const customFlags = {
            "Facebook.EventSourceUrl": window.location.href
        };
        (_a = this.getMParticle()) === null || _a === void 0 ? void 0 : _a.logEvent(MParticleEvents.CHECKOUT_METHOD_CLICK, CustomEventType.UserPreference, attributes, customFlags);
    }
    logClickActionEvent(attributes) {
        var _a;
        const customFlags = {
            "Facebook.EventSourceUrl": window.location.href
        };
        (_a = this.getMParticle()) === null || _a === void 0 ? void 0 : _a.logEvent(MParticleEvents.CLICK_ACTION, CustomEventType.Navigation, attributes, customFlags);
    }
    logVideoStartEvent(attributes) {
        var _a;
        const customFlags = {
            "Facebook.EventSourceUrl": window.location.href
        };
        (_a = this.getMParticle()) === null || _a === void 0 ? void 0 : _a.logEvent(MParticleEvents.VIDEO_START, CustomEventType.Navigation, attributes, customFlags);
    }
    logViewedShopAllListEvent(attributes) {
        var _a;
        const customFlags = {
            "Facebook.EventSourceUrl": window.location.href
        };
        (_a = this.getMParticle()) === null || _a === void 0 ? void 0 : _a.logEvent(MParticleEvents.VIEWED_SHOP_ALL_LIST, CustomEventType.Navigation, attributes, customFlags);
    }
    logUnknownEvent(attributes, analyticsProducts, transactionAttributes) {
        var _a;
        const customFlags = {
            "Facebook.EventSourceUrl": window.location.href
        };
        (_a = this.getMParticle()) === null || _a === void 0 ? void 0 : _a.eCommerce.logProductAction(MParticleEvents.UNKNOWN, analyticsProducts, attributes, customFlags, transactionAttributes);
    }
    logAddToCartEvent(attributes, analyticsProducts, transactionAttributes) {
        var _a;
        const customFlags = {
            "Facebook.EventSourceUrl": window.location.href
        };
        (_a = this.getMParticle()) === null || _a === void 0 ? void 0 : _a.eCommerce.logProductAction(MParticleEvents.ADD_TO_CART, analyticsProducts, attributes, customFlags, transactionAttributes);
    }
    logPurchaseEvent(attributes, analyticsProducts, transactionAttributes) {
        var _a;
        const customFlags = {
            "Facebook.EventSourceUrl": window.location.href
        };
        (_a = this.getMParticle()) === null || _a === void 0 ? void 0 : _a.eCommerce.logProductAction(MParticleEvents.PURCHASE, analyticsProducts, attributes, customFlags, transactionAttributes);
    }
    logRemoveFromCartEvent(attributes, analyticsProducts, transactionAttributes) {
        var _a;
        const customFlags = {
            "Facebook.EventSourceUrl": window.location.href
        };
        (_a = this.getMParticle()) === null || _a === void 0 ? void 0 : _a.eCommerce.logProductAction(MParticleEvents.REMOVE_FROM_CART, analyticsProducts, attributes, customFlags, transactionAttributes);
    }
    logCheckoutOptionEvent(attributes, analyticsProducts, transactionAttributes) {
        var _a;
        const customFlags = {
            "Facebook.EventSourceUrl": window.location.href
        };
        (_a = this.getMParticle()) === null || _a === void 0 ? void 0 : _a.eCommerce.logProductAction(MParticleEvents.CHECKOUT_OPTION, analyticsProducts, attributes, customFlags, transactionAttributes);
    }
    logClickEvent(attributes, analyticsProducts, transactionAttributes) {
        var _a;
        const customFlags = {
            "Facebook.EventSourceUrl": window.location.href
        };
        (_a = this.getMParticle()) === null || _a === void 0 ? void 0 : _a.eCommerce.logProductAction(MParticleEvents.CLICK, analyticsProducts, attributes, customFlags, transactionAttributes);
    }
    logViewDetailEvent(attributes, analyticsProducts, transactionAttributes) {
        var _a;
        const customFlags = {
            "Facebook.EventSourceUrl": window.location.href
        };
        (_a = this.getMParticle()) === null || _a === void 0 ? void 0 : _a.eCommerce.logProductAction(MParticleEvents.VIEW_DETAIL, analyticsProducts, attributes, customFlags, transactionAttributes);
    }
    getMParticle() {
        if (!window.mParticle) {
            console.log(ANALYTICS_MESSAGES.analyticsLogCustomEvent.info);
            return null;
        }
        return window.mParticle;
    }
}
__decorate([
    withCommonAttributes(MParticleEvents.PRODUCT_VIEWED),
    logOnlyOnce(MParticleEvents.PRODUCT_VIEWED)
], EventLogger.prototype, "logProductViewedEvent", null);
__decorate([
    withCommonAttributes(MParticleEvents.PRODUCT_CLICKED)
], EventLogger.prototype, "logProductClickedEvent", null);
__decorate([
    withCommonAttributes(MParticleEvents.IAB_IMPRESSION),
    logOnlyOnce(MParticleEvents.IAB_IMPRESSION)
], EventLogger.prototype, "logIABImpressionEvent", null);
__decorate([
    withCommonAttributes(MParticleEvents.SPONSOR_CLICKED)
], EventLogger.prototype, "logSponsorClickedEvent", null);
__decorate([
    withCommonAttributes(MParticleEvents.SPONSOR_VIEWED),
    logOnlyOnce(MParticleEvents.SPONSOR_VIEWED)
], EventLogger.prototype, "logSponsorViewedEvent", null);
__decorate([
    withCommonAttributes(MParticleEvents.SPONSOR_IMPRESSION),
    logOnlyOnce(MParticleEvents.SPONSOR_IMPRESSION)
], EventLogger.prototype, "logSponsorImpressionEvent", null);
__decorate([
    withCommonAttributes(MParticleEvents.SHOPPABLE_PAGE_LOAD),
    logOnlyOnce(MParticleEvents.SHOPPABLE_PAGE_LOAD)
], EventLogger.prototype, "logPageLoadEvent", null);
__decorate([
    withCommonAttributes(MParticleEvents.SHOPPABLE_CONTENT_LOAD),
    logOnlyOnce(MParticleEvents.SHOPPABLE_CONTENT_LOAD)
], EventLogger.prototype, "logShoppableContentLoadedEvent", null);
__decorate([
    withCommonAttributes(MParticleEvents.PRODUCT_LOADED),
    logOnlyOnce(MParticleEvents.PRODUCT_LOADED)
], EventLogger.prototype, "logProductLoadedEvent", null);
__decorate([
    withCommonAttributes(MParticleEvents.BACK_IN_STOCK)
], EventLogger.prototype, "logBackInStockEvent", null);
__decorate([
    withCommonAttributes(MParticleEvents.CART_VIEWED)
], EventLogger.prototype, "logCartViewedEvent", null);
__decorate([
    withCommonAttributes(MParticleEvents.CHECKOUT_METHOD_CLICK)
], EventLogger.prototype, "logCheckoutMethodClickEvent", null);
__decorate([
    withCommonAttributes(MParticleEvents.CLICK_ACTION)
], EventLogger.prototype, "logClickActionEvent", null);
__decorate([
    withCommonAttributes(MParticleEvents.VIDEO_START)
], EventLogger.prototype, "logVideoStartEvent", null);
__decorate([
    withCommonAttributes(MParticleEvents.VIEWED_SHOP_ALL_LIST)
], EventLogger.prototype, "logViewedShopAllListEvent", null);
__decorate([
    withCommonAttributes(MParticleEvents.UNKNOWN)
], EventLogger.prototype, "logUnknownEvent", null);
__decorate([
    withCommonAttributes(MParticleEvents.ADD_TO_CART)
], EventLogger.prototype, "logAddToCartEvent", null);
__decorate([
    withCommonAttributes(MParticleEvents.PURCHASE)
], EventLogger.prototype, "logPurchaseEvent", null);
__decorate([
    withCommonAttributes(MParticleEvents.REMOVE_FROM_CART)
], EventLogger.prototype, "logRemoveFromCartEvent", null);
__decorate([
    withCommonAttributes(MParticleEvents.CHECKOUT_OPTION)
], EventLogger.prototype, "logCheckoutOptionEvent", null);
__decorate([
    withCommonAttributes(MParticleEvents.CLICK)
], EventLogger.prototype, "logClickEvent", null);
__decorate([
    withCommonAttributes(MParticleEvents.VIEW_DETAIL)
], EventLogger.prototype, "logViewDetailEvent", null);
export const eventLogger = new EventLogger();
