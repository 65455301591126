import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import BadgeLabel from 'components/BadgeLabel';
import LiveBlog from 'components/packages/LiveBlog';
import { BylineList } from 'components/Byline';
import ContentTimestamp from 'components/ContentTimestamp';
import { package as PackagePropType } from 'lib/CustomPropTypes';
import { getItemData } from '../../../utils/packageDataManager';
import { ContentMedia } from '../../../ContentMedia';
import { Headline } from '../../../Headline';


/**
 *
 * @param {object} props
 * @returns {React.ReactElement}
 */
export function LiveBlogContent(props) {
  const {
    content,
    itemData,
    itemIndex,
    itemIndexInColumn,
    columnIndex,
    showLive,
    hideHeadlineAndDek,
  } = props;

  const isLiveVideo = showLive;
  const {
    aspectRatio,
    authors,
    badgeText,
    delimiter,
    dek,
    getBadgeType,
    hasEyebrow,
    hasRelatedContent,
    headline,
    headlineType,
    headlineUrl,
    item,
    metadata,
    multiHeadlineSize,
    multiMediaSize,
    packageMetadata,
    showAuthor,
    showHeadlineOnly,
    showDek,
    showTimestamp,
    storyType,
    unibrow,
    liveBlogStyle,
  } = getItemData(itemData,
    'multiStorylineItemData',
    {
      content, showLive, columnIndex, itemIndex, itemIndexInColumn,
    });
  // it would be nice if !hideLiveBlogVisual used the standard item content
  // pattern of mediaSize === 'none'
  const { hideLiveBlogVisual } = metadata;
  const { hideLiveBlogHeadlineText } = metadata;
  const hasHeadline = !hideLiveBlogHeadlineText;
  const noLiveBlogTopMargin = (hideLiveBlogVisual && !hasHeadline && !hasEyebrow);
  const noHeadlineNoEybrow = !hasHeadline && !hasEyebrow;
  /*
    Vertical Stack
    media (non-mobile)
    badge
    headline
    dek
    byline
    media (mobile)
  */
  const nothingAboveByline = !hasHeadline && !hasEyebrow && !showDek && hideLiveBlogVisual;
  /**
   *
   * @param {string} customClass
   */
  const mediaSection = (customClass) => {
    if (!metadata.hideLiveBlogVisual && multiMediaSize !== 'none' && itemData?.computedValues?.teaseImage !== null) {
      return (
        <div className={classNames(
          'media',
          'liveblog',
          customClass,
          {
            'live-video-container': isLiveVideo,
          },
        )}
        >
          {(!noHeadlineNoEybrow && !hideHeadlineAndDek) && (
            <div className="media-top-spacer mobile-multistory-media" />
          )}
          <ContentMedia
            aspectRatio={aspectRatio}
            contentItem={itemData}
            packageMetadata={packageMetadata}
            mediaSize={multiMediaSize}
            isLiveVideo={isLiveVideo}
          />
        </div>
      );
    }
    return null;
  };

  const liveBlogContent = (
    <>
      {hideHeadlineAndDek ? (
        <>
          {
            mediaSection('non-mobile-multistory-media')
          }
          { !noLiveBlogTopMargin && (
            <div className="multistory-liveblog-related-spacer mobile-multistory-media" />
          )}
        </>
      ) : (
        <>
          {
            mediaSection('non-mobile-multistory-media')
          }
          { !metadata.hideLiveBlogHeadlineText || hasEyebrow
            ? (
              <div className={classNames(
                'headline-item-container',
                {
                  'headline-container-small': multiMediaSize === 'multiSmall',
                },
              )}
              >
                <div className="standard-layout__badge">
                  {hasEyebrow && !hideLiveBlogVisual && (
                    <div className="badge-top-spacer" />
                  )}
                  {hasEyebrow && (
                    <BadgeLabel
                      badgeType={getBadgeType()}
                      badgeText={badgeText}
                      eyebrowText={unibrow?.text}
                      url={unibrow?.url?.primary}
                    />
                  )}
                </div>
                { !metadata.hideLiveBlogHeadlineText && (
                  <div className={classNames({
                    'headline-small': multiHeadlineSize === 'small',
                    'headline-standard': multiHeadlineSize === 'standard',
                    'headline-large': multiHeadlineSize === 'large',
                    'headline-xl': multiHeadlineSize === 'extraLarge',
                    'headline-xxl': multiHeadlineSize === 'xxl',
                  })}
                  >
                    { hasEyebrow
                    && (multiMediaSize === 'multiStandard') && (
                      <div className="headline-top-spacer" />
                    )}
                    { (!hasEyebrow
                    && multiMediaSize === 'multiStandard'
                    && !hideLiveBlogVisual) && (
                      <div className="headline-under-media-spacer" />
                    )}
                    <Headline
                      text={headline}
                      headlineSize={headlineType}
                      storyType={storyType}
                      url={headlineUrl}
                      showHeadlineOnly={showHeadlineOnly}
                      noBottomSpace={!hasRelatedContent}
                    />
                  </div>
                )}
              </div>
            )
            : null}
          {showDek && (
            <>
              <div className="dek-top-spacer" />
              <div className="multi-story__dek publico-txt f3 lh-copy fw4">
                {dek}
              </div>
            </>
          )}
          { (showAuthor || showTimestamp) && (
            <>
              {!nothingAboveByline && (
                <div className="author-top-spacer" />
              )}
              <div className="multistory-byline multi-story-main-content">
                {showAuthor && (
                  <BylineList
                    authors={authors}
                  />
                )}
                {showAuthor && showTimestamp && (
                  <span className="ma1">
                    {delimiter}
                  </span>
                )}
                {showTimestamp && (
                  <ContentTimestamp
                    dateCreated={item?.dateCreated}
                    datePublished={item?.datePublished}
                    delimiter=", "
                  />
                )}
              </div>
            </>
          )}
          { !noLiveBlogTopMargin && (
            <div className="multistory-liveblog-related-spacer mobile-multistory-media" />
          )}
        </>
      )}
      <div className={classNames(
        'multistory-liveblog-content',
        {
          'multistory-no-liveblog-top-margin': noLiveBlogTopMargin,
        },
      )}
      >
        <LiveBlog
          content={content}
          itemIndex={itemIndex}
          liveBlogStyle={liveBlogStyle}
          isMultiStoryline
        />
      </div>
    </>
  );
  return liveBlogContent;
}

LiveBlogContent.propTypes = {
  content: PackagePropType.isRequired,
  /* eslint-disable-next-line react/forbid-prop-types */
  itemData: PropTypes.object.isRequired,
  itemIndex: PropTypes.number,
  columnIndex: PropTypes.number,
  showLive: PropTypes.bool,
  itemIndexInColumn: PropTypes.number,
  hideHeadlineAndDek: PropTypes.bool,
};

LiveBlogContent.defaultProps = {
  itemIndex: null,
  columnIndex: null,
  showLive: false,
  itemIndexInColumn: null,
  hideHeadlineAndDek: false,
};
