import React from 'react';

/**
 * SVG eye icon to use in password field to display password as text
 * @returns {React.ReactElement} The rendered component.
 */
const ShowEye = () => (
  <svg fill="none" height="19" viewBox="0 0 20 19" width="20" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#555" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
      <path d="m1.75 9.5s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6-8.25-6-8.25-6z" />
      <path d="m10 11.75c1.2426 0 2.25-1.0074 2.25-2.25 0-1.24264-1.0074-2.25-2.25-2.25-1.24264 0-2.25 1.00736-2.25 2.25 0 1.2426 1.00736 2.25 2.25 2.25z" />
    </g>
  </svg>
);

export { ShowEye };
