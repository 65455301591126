import React from 'react';
import { useBedrockRegistration } from 'store';

/**
 * Displays the user registration form
 *
 * @returns {React.ReactElement} The rendered component.
 */
const RegistrationScreen = () => {
  const user = useBedrockRegistration((state) => state.user);

  return (
    <>
      <h2 data-testid="account-registration">Create your account</h2>
      <p>
        User email:&nbsp;
        {user.email || 'example@example.com'}
      </p>
    </>
  );
};

export { RegistrationScreen };
