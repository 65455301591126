/* eslint-disable camelcase */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { AUTH_FIELDS_TO_INCLUDE } from 'lib/constants';
import { bedrockApi as api, decodeJWT } from './utils';


export const INITIAL_STATE = {
  user: {
    user_id: null,
    last_name: '',
    first_name: '',
    email: '',
    birth_year: '',
    gender: '',
    address_line1: '',
    address_line2: null,
    state: '',
    zipcode: '',
    phone_number: '',
  },
  access_token: null,
  error: '',
  loading: false,
  brand: '',
  showAuthenticationModal: false,
};

/**
 * Creates the zustand store with initial state and actions for the Bedrock Registration service
 * @param {Function} set - The function to update the state.
 * @param {Function} get - The function to get the current state.
 * @returns {object} The store with state and actions.
 */
export const bedrockRegistrationStore = (set, get) => ({
  ...INITIAL_STATE,
  /**
   * Resets the data in the store to its initial state.
   * @returns {void}
   */
  reset: () => set({ ...INITIAL_STATE }, false, 'reset'),

  /**
   * Sets the loading state in the store.
   * @param {boolean} loading - The loading state.
   * @returns {void}
   */
  setLoading: (loading) => set({ loading }, false, 'setLoading'),

  /**
   * Sets the email in the store.
   * @param {string} email
   */
  setEmail: (email) => set({ user: { email } }, false, 'setEmail'),

  /**
   * Searches for a user by email and vertical/brand
   * @param {string} email
   * @param {VerticalType} brand
   * @returns {Promise<object>} The response from the API.
   */
  searchUserByEmail: async (email, brand) => {
    get().setLoading(true);

    try {
      const response = await api('/users/search/profile', {
        body: {
          email,
          brand,
        },
      });
      get().setLoading(false);
      return response;
    } catch (error) {
      return error;
    }
  },
  /**
   * Checks user password for login
   * @param {string} password
   * @returns {string} The status of the login.
   */
  userSignin: async (password) => {
    let status;

    get().setLoading(true);

    try {
      const response = await api('/signin', {
        body: {
          email: get().user.email,
          password,
          brand: 'today',
          remember_me: false,
        },
      });

      if (response?.success) {
        // user has an email registerd with an account, send them to the login screen
        set({
          access_token: response?.data?.access_token,
        }, false, 'userSignin');

        // set the session cookie
        get().restoreSession(response?.data?.access_token);

        status = 'loginSuccess';
      } else {
        // something went wrong with the api request, set an error
        set({ error: response?.error?.api_message }, false, 'userSigninError');
      }
    } catch (err) {
      // someting went wrong with the call to the api, set an error
      set({ error: err.message }, false, 'userSigninCallError');
      get().setLoading(false);
      return status;
    }
    get().setLoading(false);
    return status;
  },

  /**
   * Identifies a user with a one time codes
   * @param {string} oneTimeCode
   * @returns {string} The status of the one time code.
   */
  verifyOneTimeCode: async (oneTimeCode) => {
    let status;

    try {
      const response = await api('/users/otp/verify', {
        body: {
          email: get().user.email,
          brand: get().brand,
          one_time_code: oneTimeCode,
        },
      });

      if (response?.success) {
        // user enters their one time code correctly
        status = 'loginSuccess';
      } else {
        // something went wrong with the api request, set an error
        set({ error: response?.error?.user_message }, false, 'verifyOneTimeCode');
      }
    } catch (err) {
      set({ error: err.message }, false, 'verifyOneTimeCode');
      return status;
    }
    return status;
  },

  /**
   * Sends the user a one time code for login verification
   */
  requestOneTimeCode: async () => {
    let status;

    try {
      const response = await api('/users/otp/request', {
        body: {
          email: get().user.email,
          brand: get().brand,
        },
        headers: {
          Authorization: `Bearer ${get().clientToken}`,
        },
      });

      if (response?.success) {
        // user enters their password correctly
        set({ user: response?.data?.access_token }, false, 'oneTimeCode');
        status = 'verifyOneTimeCode';
      } else {
        // something went wrong with the api request, set an error
        set({ error: response?.error?.api_message }, false, 'oneTimeCode');
      }
    } catch (err) {
      set({ error: err.message }, false, 'oneTimeCode');
      return status;
    }
    return status;
  },
  /**
   * Clears the user token from the store and the cookie
   * @returns {string} The status of the signout.
   */
  userSignout: async () => {
    let status = 'unknown';

    get().setLoading(true);
    try {
      const response = await api(`/users/${get().user.user_id}/signout`);
      if (response?.success) {
        // user has an email registerd with an account, send them to the login screen
        get().reset();

        status = 'default';
        get().setLoading(false);
      } else {
        // something went wrong with the api request, set an error
        set({ error: response?.error?.api_message }, false, 'userSignout');
        get().setLoading(false);
      }
      return status;
    } catch (err) {
      set({ error: err.message }, false, 'userSignout');
      get().setLoading(false);
      return status;
    }
  },

  /**
   * Fetches the user profile based on the user id
   * @param {string} access_token - The access token to restore the session
   * @returns {void}
   */
  restoreSession: async (access_token) => {
    get().setLoading(true);

    // Early return if no token is provided
    if (!access_token) {
      set({ error: 'No token provided' }, false, 'restoreSession');
      get().setLoading(false);
      return;
    }

    // Get the user id form the token and fetch profile
    const { sub: user_id } = decodeJWT(access_token);

    // Early return if no user id is found in token
    if (!user_id) {
      get().reset();
      set({ error: 'User ID not found in token -> signout' }, false, 'restoreSession');
      return;
    }

    // Fetch the user profile
    try {
      const response = await api(`/users/${user_id}/profile`, {
        method: 'get',
      });

      if (response.success) {
        set({ user: response.data }, false, 'restoreSession');
      } else {
        set({ error: response?.error?.api_message }, false, 'restoreSession');
      }
    } catch (err) {
      set({ error: err.message }, false, 'restoreSession');
    }
    get().setLoading(false);
  },

  /**
   * Handles user signin with Apple or Google
   * @param {string} idToken token recieved from Apple/Google login response
   * @param {string} platform whether user is signing in with Google or Apple
   * @returns {string} The status of the login.
   */
  socialLogin: async (idToken, platform) => {
    let status;

    try {
      const response = await api('/users/social/login', {
        body: {
          id_token: idToken,
          brand: get().brand,
          platform,
          first_name: get().user.first_name,
          last_name: get().user.last_name,
        },
      });

      if (response?.success) {
        // user succuessfully logs in with social login
        set({ user: response?.data?.access_token }, false, 'loginSuccess');
        status = 'loginSuccess';
      } else {
        // something went wrong with the api request, set an error
        set({ error: response?.error?.api_message }, false, 'login');
      }
    } catch (err) {
      set({ error: err.message }, false, 'login');
      return status;
    }
    return status;
  },

  /**
   * Registers a new user
   * @param {object} props - The user registration props.
   * @param {string} props.password - The user password.
   * @param {string} props.brand - The brand/vertical.
   * @param {string} props.first_name - The user first name.
   * @param {string} props.last_name - The user last name.
   * @param {string} [props.source] - The source of the registration.
   * @param {object} [props.profile] - Additional user profile data.
   * @param {string} props.email
   * @returns {string} The status of the registration.
   */
  userRegistration: async (props) => {
    let status;

    const body = Object.fromEntries(
      AUTH_FIELDS_TO_INCLUDE
        .map((field) => [field, props[field]])
        .filter(([, value]) => value !== undefined),
    );

    try {
      const response = await api('/users/register', {
        body,
      });

      if (response?.success) {
        const access_token = response?.data?.access_token;
        set({
          access_token,
        }, false, 'registrationSuccess');

        // set the session cookie
        get().restoreSession(access_token);

        status = 'registrationSuccess';
      } else {
        // something went wrong with the api request, set an error
        set({ error: response?.error?.api_message }, false, 'registrationError');
        status = 'registrationError';
      }
    } catch (err) {
      set({ error: err.message }, false, 'registrationError');
      status = 'registrationError';
    }
    return status;
  },

  /**
   * Registers user via a social platform: google, apple etc.
   * @param {string} idToken
   * @param {string} platform
   * @returns {string} The status of the registration.
   */
  userSocialRegistration: async (idToken, platform) => {
    let status;

    try {
      const response = await api('/users/social/register', {
        body: {
          id_token: idToken,
          brand: get().brand,
          platform,
          first_name: get().user.first_name,
          last_name: get().user.last_name,
          email: get().user.email,
          remember_me: false,
        },
      });

      if (response?.success) {
        set({ user: response?.data?.access_token }, false, 'userSocialRegistration');
        status = 'userSocialRegistrationSuccess';
      } else {
        // something went wrong with the api request, set an error
        set({ error: response?.error?.api_message }, false, 'userSocialRegistration');
      }
    } catch (err) {
      set({ error: err.message }, false, 'userSocialRegistration');
      return status;
    }
    return status;
  },


  /**
   * Sets the AuthenticationModal state in the store.
   * @param {boolean} showAuthenticationModal
   */
  setAuthenticationModal: (showAuthenticationModal) => set({ showAuthenticationModal }, false, 'setAuthenticationModal'),
});

/**
 * Enable the devtools in development mode only
 */
const isDevelop = process.env.NODE_ENV === 'development';

const devToolsProps = {
  name: 'useBedrockRegistration',
  anonymousActionType: 'action',
  serialize: true,
  // eslint-disable-next-line jsdoc/require-jsdoc
  actionSanitizer: (action) => ({
    ...action,
    type: `BedrockRegistration/${action.type}`,
  }),
};

// eslint-disable-next-line jsdoc/require-jsdoc
const middlewares = (f) => (isDevelop ? devtools(f, devToolsProps) : f);

/* Create Store */
export const useBedrockRegistration = create(middlewares(bedrockRegistrationStore));

