/**
 * Sends a POST request to the specified URL.
 *
 * @param {string} url - The URL to fetch from.
 * @param {object} [options={}] - The options for the request.
 * @param {string} [options.mparticleId] - The mParticle ID. If not provided, it will be fetched from the window.mParticle object.
 * @returns {Promise<optimizebject>} The response data as a JavaScript object. Throws an error if the network response was not ok or if the fetch operation fails.
 */
export function api(url, { mparticleId } = {}) {
  const options = {
    method: 'post',
    headers: {
      'X-Auth-Token': window.HFSapi?.identity.token || null,
      'Content-Type': 'application/json',
    },
    credentials: 'omit',
    body: JSON.stringify({
      customerId: window.HFSapi?.identity.user.id || null,
      // getCustomer call does not require mparticleId
      mparticleId: mparticleId || window.mParticle?.Identity?.getCurrentUser?.().getMPID() || '',
    }),
  };

  return fetch(`${window.origin}${url}`, { ...options })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .catch((error) => {
      throw new Error(error.message);
    });
}


/**
 * Makes a POST request to the Bedrock API.
 *
 * @param {string} url - The endpoint URL to which the request is sent.
 * @param {object} [options] - The options for the request.
 * @param {object} [options.body] - The body of the request.
 * @param {string} [options.method='post'] - The HTTP method of the request.
 * @param {object} [options.headers] - The headers of the request.
 * @returns {Promise<object>} - A promise that resolves to the JSON response.
 * @throws {Error} - Throws an error if the request fails or the response indicates failure.
 */
export function bedrockApi(url, {
  body = {},
  method = 'post',
  headers = {},
} = {}) {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  };

  if (method.toLocaleLowerCase() !== 'get') {
    options.body = JSON.stringify(body);
  }

  return fetch(`/auth${url}`, options)
    .then((response) => response.json());
}


/**
 * Decodes a JSON Web Token (JWT).
 *
 * @param {string} token - The JWT to decode.
 * @returns {object|null} The decoded token payload as a JavaScript object, or null if decoding fails.
 */
export function decodeJWT(token) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (error) {
    return {};
  }
}
