import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Button } from 'components/Button';
import { useBedrockRegistration } from 'store';

/**
 * LoginSuccess component for handling a successful login.
 * @param {object} props - Component props.
 * @param {Function} props.setScreen - Function to set the screen.
 * @returns {React.ReactElement} The rendered component.
 */
const LoginSuccess = ({ setScreen }) => {
  const user = useBedrockRegistration((state) => state.user);
  const userSignout = useBedrockRegistration((state) => state.userSignout);

  const handleUserSignout = useCallback(() => {
    userSignout();
    setScreen('default');
  }, [userSignout, setScreen]);

  return (
    <>
      <h2 data-testid="account-login-success">Success!</h2>
      <p>Your are now signed in.</p>
      <pre>{JSON.stringify(user, null, 2)}</pre>
      <Button
        displayType="submit"
        title="Signout"
        disabled={!user?.user_id}
        onClick={handleUserSignout}
      />
    </>
  );
};

LoginSuccess.propTypes = {
  setScreen: PropTypes.func.isRequired,
};

export { LoginSuccess };
