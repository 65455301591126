import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash.get';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import {
  content as ContentPropType,
  layoutContext as layoutContextPropType,
} from 'lib/CustomPropTypes';
import { imageAltForImage } from 'lib/imageAlt';
import { hasFeaturedAuthor } from 'lib/featuredAuthor';
import { teaseCard as paidContent } from 'lib/paidContentUtils';
import { getDeepLink } from 'lib/deepLinking';
import buildUrl from 'lib/buildUrl';
import { isThinkModule } from 'lib/teaseUtils';

import { PaidContent } from 'components/PaidContent';
import Ad from 'components/Ad';
import ContentTimestamp from 'components/ContentTimestamp';
import { Dek } from 'components/Dek';
import Link from 'components/Link';
import { Picture } from 'components/Picture';
import RelatedContent from 'components/RelatedContent';
import TeaseCategory from 'components/TeaseCategory';
import { Unibrow } from 'components/Unibrow';
import { TypeIconInline } from 'components/TypeIconInline/';

import { BylineList } from 'components/Byline';

import './styles.themed.scss';
import { Save } from 'components/SocialShareMenu/Save';
import { TeaseRow } from 'components/packages/TeaseRow';
import RecipeDetails from 'components/Recipe/RecipeDetails';

const block = 'tease-card';

class TeaseCard extends Component {
  static propTypes = {
    additionalClasses: PropTypes.shape({
      content: PropTypes.string,
      dek: PropTypes.string,
      featuredAuthor: PropTypes.string,
      featuredAuthorImage: PropTypes.string,
      info: PropTypes.string,
      liveText: PropTypes.string,
      picture: PropTypes.string,
      pictureLink: PropTypes.string,
      title: PropTypes.string,
      // Add more as needed
    }),
    children: PropTypes.any,
    content: ContentPropType.isRequired,
    imageClick: PropTypes.func,
    isRailAdjacent: PropTypes.bool,
    isMobileOnlyComponent: PropTypes.bool,
    packageId: PropTypes.string,
    pkgClassName: PropTypes.string,
    pkgType: PropTypes.string,
    showFeaturedAuthor: PropTypes.bool,
    showAuthor: PropTypes.bool,
    showDek: PropTypes.bool,
    showTeaseImage: PropTypes.bool,
    showTimestamp: PropTypes.bool,
    showLive: PropTypes.bool,
    showUnibrowInset: PropTypes.bool,
    size: PropTypes.string,
    vertical: PropTypes.string,
    typeIconSize: PropTypes.string,
  };

  static contextTypes = {
    ...layoutContextPropType,
  };

  static defaultProps = {
    additionalClasses: {
      content: null,
      dek: null,
      featuredAuthor: null,
      featuredAuthorImage: null,
      info: null,
      liveText: null,
      picture: null,
      pictureLink: null,
      title: null,
      // Add more as needed
    },
    children: null,
    showAuthor: false,
    showFeaturedAuthor: false,
    showTimestamp: false,
    showDek: false,
    showTeaseImage: true,
    showUnibrowInset: true,
    imageClick: null,
    isRailAdjacent: false,
    isMobileOnlyComponent: false,
    showLive: false,
    packageId: null,
    pkgClassName: null,
    pkgType: null,
    size: 'small',
    typeIconSize: 'small',
    vertical: '',
  };

  getClassName() {
    const {
      additionalClasses,
      content: { type },
      pkgType,
    } = this.props;

    const hasImageCredit = pkgType === 'straightUp' && type !== 'video' && type !== 'relatedRecipe';

    const featuredAuthor = this.hasFeaturedAuthor();
    const nativeAd = this.isNative();
    const { vertical } = this.props;
    const classes = classNames(
      block,
      'tease-card__content',
      `tease-card__content--${vertical}`,
      additionalClasses.content,
      {
        'tease-card__has-image-credit': hasImageCredit,
        'tease-card__title--is-paid-content': nativeAd,
        'tease-card--no-main-image': featuredAuthor || !this.hasImage(),
        hasFeaturedAuthor: featuredAuthor,
      },
    );

    return classes;
  }

  getCategoryStyle() {
    if (this.isNative()) {
      return 'sponsored';
    }
    if (this.hasFeaturedAuthor()) {
      return 'hasFeaturedAuthor';
    }
    return 'tease-card__eyebrow';
  }

  getPaidSponsor() {
    const { content } = this.props;
    const paidContentName = content?.item?.source?.name;

    const organization = content?.item?.source?.organization;
    const { name, externalUrl } = organization || {};

    if (paidContentName) {
      return paidContentName;
    }
    if (!name) {
      return null;
    }

    const sponsorName = (
      <span className="tease-card__sponsor-name">
        {' '}
        {name}
        {' '}
      </span>
    );
    if (externalUrl) {
      return (
        <p className="tease-card__paid-sponsor">
          <Link to={externalUrl}>
            {sponsorName}
          </Link>
        </p>
      );
    }
    return (
      <p className="tease-card__paid-sponsor">
        {sponsorName}
      </p>
    );
  }

  getUnibrow() {
    const {
      content,
      content: {
        computedValues,
      },
    } = this.props;

    // computedValues is not always there, depending on who calls.  In video fronts, unibrow
    // is a first class citizen, and computedValues does not exist on content
    return computedValues?.unibrow || content.unibrow;
  }

  hasFeaturedAuthor() {
    const {
      showFeaturedAuthor,
      content,
      content: {
        computedValues,
        type,
      },
    } = this.props;
    const { item } = computedValues?.item ? computedValues : content;

    if (!showFeaturedAuthor) return false;

    return hasFeaturedAuthor(item, type);
  }

  hasImage() {
    const {
      content,
      content: {
        computedValues,
      },
      showTeaseImage,
    } = this.props;

    const {
      coverImage,
      mainImage,
      teaseImage,
    } = computedValues || content;

    return showTeaseImage === true && (coverImage || mainImage || teaseImage);
  }

  isNative() {
    const { content, vertical } = this.props;
    const { nativeAd } = content.item || content;
    return nativeAd && vertical !== 'sponsoredcontent';
  }

  author() {
    const {
      content: {
        item,
      },
      showAuthor,
    } = this.props;

    if (!item || !item.authors) {
      return null;
    }

    // Filter to the first four 'authors'
    const authors = item.authors
      .filter((author, i) => author.authorType === 'author' && i < 4);

    if (!this.hasFeaturedAuthor() && showAuthor && Array.isArray(authors) && authors.length) {
      return (
        <span className="tease-card__author">
          <BylineList authors={authors} />
        </span>
      );
    }
    return null;
  }

  picture(articleModuleClassName) {
    if (this.hasFeaturedAuthor()) {
      return null;
    }

    const {
      additionalClasses,
      children,
      content,
      imageClick,
      pkgClassName,
      pkgType,
      size,
      vertical,
      showUnibrowInset,
    } = this.props;

    const {
      isS: isMobile = false,
      isFluidWidthPage,
    } = this.context;

    const {
      type,
      id,
    } = content;

    const deepLink = getDeepLink(content, pkgType, isMobile);
    const target = deepLink && '_blank';
    const rel = deepLink && 'noopener noreferrer';
    const url = deepLink ? deepLink.url : buildUrl(content);
    // Add missing child element properties
    const childElements = React.Children.map(children, (child) => React.cloneElement(child, {
      type,
      ...child.props,
    }));

    const isStraightUp = pkgClassName === 'pkg straightUp';

    return (
      /* eslint-disable jsx-a11y/no-static-element-interactions */
      /* eslint-disable jsx-a11y/click-events-have-key-events */
      <div
        className={classNames(
          `${block}__picture`,
          articleModuleClassName,
          additionalClasses.picture,
          {
            'self-start': isStraightUp,
          },
        )}
        onClick={imageClick}
        data-test="tease-card-tease-picture"
      >
        <Link
          className={classNames(
            'tease-card__picture-link',
            additionalClasses.pictureLink,
          )}
          rel={rel}
          target={target}
          to={url}
        >
          {childElements}
        </Link>
        {showUnibrowInset && (
          <TeaseCategory
            type={type}
            unibrow={this.getUnibrow()}
            style={this.getCategoryStyle()}
            size={size}
            iconUrl={url}
            vertical={vertical}
            deepLink={deepLink}
            target={target}
            rel={rel}
          />
        )}
        {/* Renders Save button only on Today Rebrand fronts */}
        {isFluidWidthPage && (
          <Save
            contentId={id}
            contentType={type}
            options={{ isThumbnail: true, showCTA: true }}
          />
        )}
      </div>
      /* eslint-enable jsx-a11y/click-events-have-key-events */
      /* eslint-enable jsx-a11y/no-static-element-interactions */
    );
  }

  date() {
    const {
      content,
      showTimestamp,
    } = this.props;

    if (!get(content, 'item') || !showTimestamp) {
      return null;
    }

    const dateCreated = get(content, 'item.dateCreated');
    const datePublished = get(content, 'item.datePublished');

    return (
      <span className={classNames('tease-card__date')}>
        <ContentTimestamp
          showCreatedDate
          delimiter=" / "
          dateCreated={dateCreated}
          datePublished={datePublished}
        />
      </span>
    );
  }

  seasonDate() {
    const {
      content,
      pkgType,
    } = this.props;

    // Deep Link Content
    const deepLink = getDeepLink(content, pkgType);

    return (
      <span className="tease-card__deepLink--season-date">
        <span className="tease-card__deepLink--season">{deepLink.season}</span>
        <ContentTimestamp
          dateLabel="Air Date"
          dateOnly
          datePublished={deepLink.datePublished}
        />
      </span>
    );
  }

  dek() {
    const {
      additionalClasses,
      content,
      showDek,
    } = this.props;
    const { dek } = content.computedValues || content;

    if (!showDek || !dek) {
      return null;
    }

    return (
      <Dek
        dek={dek}
        styles={classNames('tease-card__dek', additionalClasses.dek)}
      />
    );
  }

  featuredAuthor(articleModuleClassName) {
    const {
      additionalClasses,
      vertical,
    } = this.props;

    const author = get(this.props, 'content.item.authors[0].person', null);

    const outerClasses = classNames(
      `${block}__featuredAuthor`,
      articleModuleClassName,
    );

    const innerClasses = classNames(
      'tease-card__featured-author',
      'card__featured-author-name',
      `tease-card__featured-author--${vertical}`,
      additionalClasses.featuredAuthor,
    );

    return (
      <span className={outerClasses}>
        <span className={innerClasses} data-testid="tease-card__featured-author">
          <BylineList authors={[author]} />
          <span>&#32;</span>
        </span>
      </span>
    );
  }

  featuredAuthorImage(articleModuleClassName) {
    const {
      additionalClasses,
      content,
      pkgType,
      content: {
        type,
      },
      size,
    } = this.props;

    const { isS: isMobile = false } = this.context;

    const outerClasses = classNames(
      `${block}__featuredAuthorImage`,
      'tease-card__featured-author-image-container',
      articleModuleClassName,
    );

    const innerClasses = classNames(
      'tease-card__featured-author-image',
      additionalClasses.featuredAuthorImage,
    );

    const author = get(this.props, 'content.item.authors[0].person', null);
    const deepLink = getDeepLink(content, pkgType, isMobile);
    const target = deepLink && '_blank';
    const rel = deepLink && 'noopener noreferrer';
    const url = deepLink ? deepLink.url : buildUrl(content);
    const authorLink = get(this.props, 'content.item.authors[0].person.url.primary', url);

    if (!author.primaryImage) {
      return null;
    }

    return (
      <div className={outerClasses}>
        <TeaseCategory
          type={type}
          unibrow={this.getUnibrow()}
          style={this.getCategoryStyle()}
          size={size}
          iconUrl={url}
          deepLink={deepLink}
          target={target}
          rel={rel}
        />
        <div className={innerClasses}>
          <Link to={authorLink} target={target} rel={rel}>
            <Picture
              responsiveFlavors={{ s: AIMS_FLAVORS.FOCAL_320x320 }}
              url={author.primaryImage.url.primary}
              alt={imageAltForImage(author.primaryImage)}
            />
          </Link>
        </div>
      </div>
    );
  }

  relatedContent(isTeaseRow) {
    const {
      content: { related = [] },
      showTeaseImage,
      isRailAdjacent,
      pkgClassName,
    } = this.props;

    const teaseRowRelatedContent = {
      subType: 'fullWidth',
      items: related,
    };

    return (
      related.length && isTeaseRow
        ? (
          <div className="tease-card--tease-row-related">
            <TeaseRow content={teaseRowRelatedContent} pkgClassName="tease-row--edge-to-edge" isStandalone={false} />
          </div>
        ) : (
          <RelatedContent
            isRailAdjacent={isRailAdjacent}
            parentNoImage={showTeaseImage === false}
            pkgClassName={pkgClassName}
            related={related}
          />
        )
    );
  }

  headline(featuredAuthor, articleModuleClassName) {
    const {
      additionalClasses,
      content,
      pkgType,
      vertical,
      showUnibrowInset,
      typeIconSize,
    } = this.props;

    const { isS: isMobile = false } = this.context;

    const isPaidHub = vertical === 'sponsoredcontent';
    const isPaidContent = content.item?.nativeAd;
    const deepLink = getDeepLink(content, pkgType, isMobile);
    const target = deepLink && '_blank';
    const rel = deepLink && 'noopener noreferrer';
    const url = deepLink ? deepLink.url : buildUrl(content);
    let headline;
    if (typeof content.computedValues !== 'undefined') {
      headline = content.computedValues?.headline;
    } else {
      headline = content.headline.actual || content.headline.primary;
    }

    return (
      <h2
        className={classNames(
          `${block}__headline`,
          'tease-card__title',
          `tease-card__title--${vertical}`,
          additionalClasses.title,
          {
            relative: content.related,
            'tease-card__title--is-paid-content': isPaidContent,
          },
        )}
      >
        <Link
          to={url}
          target={target}
          rel={rel}
          data-test="tease-card-title"
        >
          {featuredAuthor && this.featuredAuthor(articleModuleClassName)}
          {!showUnibrowInset && (
            <TypeIconInline
              type={content.type}
              taxonomy={content.item?.taxonomy}
              size={typeIconSize}
            />
          )}
          <span
            className={classNames(
              'tease-card__headline',
              {
                'tease-card__headline--no-art': !this.hasImage(),
                'tease-card__headline--is-paid-content': isPaidContent && !isPaidHub,
              },
            )}
            data-testid="tease-card__headline"
          >
            {deepLink && (
              <span className="tease-card__headline--deepLink-episode">
                {deepLink.episode}
              </span>
            )}
            {headline}
          </span>
        </Link>

        <span
          className={classNames(
            {
              'tease-card__paid-content-sponsor': isPaidContent,
            },
          )}
        >

          {this.isNative() && !isPaidHub && this.getPaidSponsor()}
        </span>
      </h2>
    );
  }

  live() {
    const {
      additionalClasses,
      showLive,
    } = this.props;

    if (!showLive) {
      return null;
    }

    return (
      <div className="tease-card__live">
        <div className="tease-card__live-icon">
          <svg height="21" width="21">
            <circle cx="10" cy="10" r="10px" stroke="red" strokeWidth="1" fill="none" />
            <circle cx="10" cy="10" r="5px" fill="red" />
          </svg>
        </div>
        <div
          className={classNames(
            'tease-card__live-text',
            additionalClasses.liveText,
          )}
        >
          <Trans>LIVE NOW</Trans>
        </div>
      </div>
    );
  }

  nativeAd() {
    const { content } = this.props;

    if (content.nativeAd) {
      return <Ad slot="native" />;
    }

    return null;
  }

  nativeTrackingPixel() {
    const { content } = this.props;

    if (content.thirdPartyTrackingUrl) {
      return <img className="native-tracking-pixel" src={content.thirdPartyTrackingUrl} alt="" />;
    }

    return null;
  }

  render() {
    const {
      additionalClasses,
      content: { item },
      content,
      isMobileOnlyComponent,
      showTeaseImage,
      packageId,
      pkgClassName,
      pkgType,
      size,
      vertical,
      showUnibrowInset,
    } = this.props;
    const {
      isFluidWidthPage,
    } = this.context;
    const { isS: isMobile = false } = this.context;
    const { type, id } = content;
    const isPaidHub = vertical === 'sponsoredcontent';
    const isPaidContent = content.item?.nativeAd;
    const isStraightUp = pkgClassName === 'pkg straightUp';
    const isOneUp = pkgType === 'oneUp';
    const isLiveBlog = content.type === 'liveBlog';
    const isTeaseRow = isFluidWidthPage && pkgType === 'straightUp';

    if (type === 'ad') {
      return <Ad slot="boxinline" offsetViewport={50} />;
    }

    if (type === paidContent.type && !isPaidHub) {
      return (
        <PaidContent
          isMobileOnlyComponent={isMobileOnlyComponent}
          {...content}
          className={classNames(
            'tease-card__content',
          )}
          vertical={vertical}
        />
      );
    }
    const deepLink = getDeepLink(content, pkgType, isMobile);
    const target = deepLink && '_blank';
    const rel = deepLink && 'noopener noreferrer';
    const url = deepLink ? deepLink.url : buildUrl(content);
    const hasImage = this.hasImage();
    const featuredAuthor = this.hasFeaturedAuthor();
    const articleModuleClassName = isThinkModule(content);
    const unibrow = this.getUnibrow();

    return (
      <article
        className={this.getClassName()}
        data-packageid={packageId}
        data-contentid={id}
        data-testid="tease-card"
      >
        <div className="tease-card__content--inner">
          {this.nativeAd()}
          {this.nativeTrackingPixel()}
          {hasImage && !featuredAuthor && this.picture(articleModuleClassName)}

          <div
            className={classNames(
              'tease-card__info',
              articleModuleClassName,
              additionalClasses.info,
              {
                'tease-card__info--is-paid-content': isPaidContent,
                'flex-wrap': isStraightUp,
                'bg-knockout-primary': isPaidHub,
              },
            )}
            data-test="tease-card__info"
            data-testid="tease-card__info"
          >
            {this.live()}
            {!showUnibrowInset && (
              <Unibrow
                unibrow={unibrow}
                vertical={vertical}
                target={target}
                rel={rel}
                deepLink={deepLink}
                hasDefaultTextStyle
              />
            )}
            {(!featuredAuthor && !hasImage && showUnibrowInset) && (
              <TeaseCategory
                type={type}
                unibrow={unibrow}
                style={this.getCategoryStyle()}
                size={size}
                iconUrl={url}
                defaultSpacing={false}
                vertical={vertical}
                target={target}
                rel={rel}
                deepLink={deepLink}
              />
            )}
            {featuredAuthor && (
              <span className={articleModuleClassName}>
                <span className={classNames('icon', 'icon-quote-mark', 'tease-card__quote')} />
              </span>
            )}
            {this.headline(featuredAuthor, articleModuleClassName, showUnibrowInset)}
            {this.dek()}
            <RecipeDetails
              type={content?.type}
              cookTime={item?.cookTime}
              prepTime={item?.prepTime}
              servingSize={item?.servingSize}
              yields={item?.yield}
            />
            {featuredAuthor && !this.featuredAuthorImage(articleModuleClassName) && (
              <p
                className={classNames(
                  `${block}__articleFeaturedAuthor`,
                  'tease-card__byline',
                )}
              >
                <span
                  className={classNames(
                    articleModuleClassName,
                    'pr',
                    'tease-card__eyebrow',
                  )}
                >
                  {unibrow?.text && unibrow.text.toUpperCase()}
                </span>
              </p>
            )}
            {(this.author() || this.date()) && (
              <p className="tease-card__byline">
                {this.author()}
                {deepLink ? this.seasonDate() : this.date()}
              </p>
            )}

            {featuredAuthor && this.featuredAuthorImage(articleModuleClassName)}

            {
              showTeaseImage && !isTeaseRow && (isOneUp || !isLiveBlog)
                && this.relatedContent()
            }
          </div>
        </div>
        {(showTeaseImage === false || isTeaseRow) && this.relatedContent(isTeaseRow)}
      </article>
    );
  }
}

export default TeaseCard;
