import { object, string, boolean } from 'yup';
import * as yup from 'yup';

const emailSchema = object().shape({
  email: string()
    .matches(
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Please enter a valid email',
    )
    .required('Please enter a valid email'),
});

const passwordLoginSchema = object().shape({
  password: string()
    .min(10, '✕ At least 10 characters')
    .required('Please enter a valid password'),
});

const PASSWORD_ERROR_TYPES = {
  LENGTH_12: '✕ At least 12 characters',
  NUMBER: '✕ Contains a number',
  LETTER: '✕ Contains a letter',
  DISTINCT_FROM_EMAIL: '✕ Cannot contain your email',
  CANNOT_USE_PASSWORD: '✕ Cannot contain "password"',
  TERMS_NOT_ACCEPTED: 'Please select the box below',
};

const passwordRegistrationSchema = object().shape({
  password: string()
    .min(12, PASSWORD_ERROR_TYPES.LENGTH_12)
    .matches(/[0-9]/, PASSWORD_ERROR_TYPES.NUMBER)
    .matches(/[a-zA-Z]/, PASSWORD_ERROR_TYPES.LETTER)
    .test('Unique', PASSWORD_ERROR_TYPES.DISTINCT_FROM_EMAIL, (values, { options }) => {
      const { userEmail } = options;
      return values !== userEmail;
    })
    .test('disallowPassword', PASSWORD_ERROR_TYPES.CANNOT_USE_PASSWORD, (values) => {
      if (typeof values === 'string') {
        return values.indexOf('password') === -1;
      }
      return true;
    }),
  termsAndConditions: boolean()
    .oneOf([true], PASSWORD_ERROR_TYPES.TERMS_NOT_ACCEPTED),
});

const oneTimeCodeSchema = object().shape({
  oneTimeCode: string()
    .length(6, '✕ one time passcode must be 6 digits')
    .matches(/^\d{6}$/, '✕ one time passcode must be a 6-digit number')
    .required('Please enter your one time passcode'),
});

const termsAndConditionsSchema = object().shape({
  termsAndConditions: yup
    .bool()
    .oneOf([true], 'Please select the box below'),
});

export {
  emailSchema,
  PASSWORD_ERROR_TYPES,
  passwordRegistrationSchema,
  passwordLoginSchema,
  oneTimeCodeSchema,
  termsAndConditionsSchema,
};
