import React from 'react';
import PropTypes from 'prop-types';
import { stub as $t } from '@nbcnews/analytics-framework';

import { marquee as MarqueePropType } from 'lib/CustomPropTypes';

import { tryParse } from 'lib/urlUtils';
import { Breaking } from './Breaking';
import { Embed } from './Embed';

if ($t !== undefined) {
  $t('register', 'marquees_loaded');
}

/**
 * @param {object} props
 * @param {MarqueeItem[]} props.marquees
 * @param {boolean} [props.isNewsLandingPage=false]
 * @returns {Element}
 */
function Marquee({ marquees = [], isNewsLandingPage = false }) {
  const marqueeTypes = marquees.map((marquee) => marquee.marqueeType) ?? [];
  React.useEffect(() => {
    $t('track', 'marquees_loaded', {
      marqueeTypes: [...marqueeTypes],
    });
  }, []);
  return (
    <>
      {marquees.map((marquee, index) => {
        const { marqueeType: type } = marquee;
        const key = `marquee-${index}`;
        // Embed marquee
        if (type === 'embed') {
          const hasHiddenQueryParam = tryParse(marquee?.embed?.url)?.searchParams?.has(
            'hiddenOnNewsHomepage',
          ) ?? false;
          if (isNewsLandingPage && hasHiddenQueryParam) {
            return null;
          }
          return (
            <Embed
              key={key}
              index={index}
              marquee={marquee}
            />
          );
        }
        // breakingNews, callOut, etc marquee
        if (type !== 'embed') {
          return (
            <Breaking
              key={key}
              marquee={marquee}
            />
          );
        }
        // Unsupported type?
        return null;
      })}
    </>
  );
}

Marquee.propTypes = {
  marquees: PropTypes.arrayOf(MarqueePropType).isRequired,
  isNewsLandingPage: PropTypes.bool,
};

Marquee.defaultProps = {
  isNewsLandingPage: false,
};

export { Marquee };
