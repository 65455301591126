import React from 'react';
import { AppleLoginButton } from './AppleLogin/index';
import { GoogleLoginButton } from './GoogleLogin/index';

/**
 * Displays Apple and Google login buttons
 * @returns {React.ReactElement} The rendered component.
 */
const SocialLogin = () => (
  <div data-testid="social-login">
    <GoogleLoginButton />
    <AppleLoginButton />
  </div>
);

export { SocialLogin };
