import React, { useEffect } from 'react';
import { useBedrockRegistration } from 'store/bedrockRegistration';
import getConfig from 'next/config';

import styles from './styles.module.scss';

const {
  publicRuntimeConfig: {
    TODAY_APPLE_CLIENT_ID,
  },
} = getConfig();

/**
 * Renders a Login with Apple Button
 */
const AppleLoginButton = () => {
  const socialLogin = useBedrockRegistration((state) => state.socialLogin);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    script.async = true;

    /**
     * Handles Apple SDK script load
     */
    script.onload = () => {
      window.AppleID.auth.init({
        clientId: TODAY_APPLE_CLIENT_ID,
        scope: 'name email',
        redirectURI: 'https://today.com',
        state: 'state',
        nonce: 'nonce',
        usePopup: true,
      });
    };

    /**
     * Handles Apple SDK script loading error
     */
    script.onerror = () => {
      console.error('Failed to load Apple ID SDK script.');
    };

    document.body.appendChild(script);

    document.addEventListener('AppleIDSignInOnSuccess', (event) => {
      // Handle successful response.
      const { idToken } = event.detail.data.id_token;
      socialLogin(idToken, 'apple').then(() => {
        console.log('successful login with apple');
      });
    });
    // Listen for authorization failures.
    document.addEventListener('AppleIDSignInOnFailure', (event) => {
      console.log(event.detail.error);
    });
  }, []);

  return (
    // adding a tabindex interferes with how apple renders the button, disabling this for now
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <div
      id="appleid-signin"
      className={styles.appleLogin}
      role="button"
      aria-label="Sign in with Apple"
    />
  );
};

export { AppleLoginButton };
