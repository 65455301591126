import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFeatureFlagContext } from 'lib/ContextTypes';
import styles from './styles.module.scss';
import { NewsNow } from './Banners/NewsNow/index';

/**
 * Get Banner
 * @param {string} type
 * @param {object} paths
 * @returns {object}
 */
const getBanner = (type, paths) => {
  switch (type) {
    // placeholder for future banners, for now this defaults to News Now
    default: return {
      url: paths.NEWS_NOW,
      component: NewsNow,
    };
  }
};

function PrimaryMediaBanner({ type, theme, dataIcid }) {
  const launchDarklyFlags = useFeatureFlagContext();

  const ffPath = launchDarklyFlags?.['fast-channel-route'] ? 'watch' : 'now';
  const paths = {
    NEWS_NOW: `https://www.nbcnews.com/${ffPath}`,
  };

  const { component: BannerContent, url } = getBanner(type, paths);

  return (
    <a
      className={classNames(styles.PrimaryMediaBannerCta, {
        [styles.LightPrimaryMediaBannerCta]: theme === 'light',
        [styles.DarkPrimaryMediaBannerCta]: theme === 'dark',
      })}
      data-icid={dataIcid}
      data-testid="primary-media-banner"
      href={url}
    >
      <BannerContent theme={theme} />
    </a>
  );
}

PrimaryMediaBanner.propTypes = {
  type: PropTypes.string.isRequired,
  theme: PropTypes.string,
  dataIcid: PropTypes.string,
};

PrimaryMediaBanner.defaultProps = {
  theme: 'light',
  dataIcid: '',
};

export { PrimaryMediaBanner };
