import { presetDefaultsConfig } from './presetDefaultsConfig';

/**
 *
 * @param {string} presetName
 */

/**
 *
 */
const itemBaseDefaults = () => ({
  multiHeadlineSize: 'standard',
  multiMediaSize: 'multiStandard',
  hasEyebrow: true,
  aspectRatio: '3 / 2',
});

/**
 *
 * @param {number} colNumber
 * @param {string} presetTemplate
 */
export const getDefaultItemsForColumn = (colNumber, presetTemplate) => {
  const presetItemsInCol = presetDefaultsConfig[presetTemplate]?.packageDefaults?.itemsInColumns[colNumber];
  return presetItemsInCol || 1;
};

/**
 *
 * @param {string} presetName
 * @param {string} presetName.presetName
 * @param {number} presetName.columnIndex
 * @param {number} presetName.itemIndexInColumn
 */
export function getItemDefaults({ presetName, columnIndex, itemIndexInColumn }) {
  // lookup default preset defaults getter/generator and call it
  const fallbackItemDefaults = presetDefaultsConfig[presetName]?.fallbackItemDefaults;
  const baseDefaults = fallbackItemDefaults || { ...itemBaseDefaults() };

  const presetDefaults = presetDefaultsConfig[presetName]?.itemDefaults?.[columnIndex]?.[itemIndexInColumn] || {};
  const itemDefaults = {
    ...baseDefaults,
    ...presetDefaults,
  };

  return itemDefaults;
}
