import React from 'react';
import { useBedrockRegistration } from 'store/bedrockRegistration';
import {
  Formik, Form, ErrorMessage, Field,
} from 'formik';
import { Button } from 'components/Button';
import PropTypes from 'prop-types';
import { termsAndConditionsSchema } from '../validationSchema';

import globalStyles from '../styles.module.scss';

/**
 * Displays the terms of use checkbox with submit button
 * @param {object} props - The component props.
 * @param {Function} props.setScreen - Function to set the screen state.
 * @returns {React.ReactElement} The rendered component.
 */
const SocialRegistrationScreen = ({ setScreen }) => {
  const userSocialRegistration = useBedrockRegistration((state) => state.userSocialRegistration);

  return (
    <>
      <Formik
        initialValues={{ termsAndConditions: false }}
        validationSchema={termsAndConditionsSchema}
        onSubmit={(values, { setSubmitting }) => {
          userSocialRegistration(values.idToken, values.platform).then((result) => {
            setSubmitting(false);
            // update the screen value based on the response
            if (result) setScreen(result);
          });
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          errors,
        }) => (
          <Form
            onSubmit={handleSubmit}
            data-testid="account-social-registration"
          >
            <div>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="termsAndConditions">
                  Before we get started...
                <p>
                   Just a quick check to make sure you’re on board with our terms and conditions.
                </p>
                <ErrorMessage name="termsAndConditions" component="div" className={globalStyles.errorMessage} />
                <Field
                  type="checkbox"
                  name="termsAndConditions"
                  data-testid="terms-checkbox"
                  className={errors.termsAndConditions ? globalStyles.checkboxError : ''}
                />
                    I have read and agree to NBCUniversal&apos;s&nbsp;
                <a
                  href=" https://www.nbcuniversal.com/terms?utm_source=nbc-terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                        Terms of Use
                </a>
                    &nbsp;and&nbsp;
                <a
                  href=" https://www.nbcuniversal.com/terms?utm_source=nbc-terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </label>
              <p>
                <br />
                I understand that TODAY and NBCUniversal may send me the latest news, promotions and more.
                <br />
                NBCUniversal Profile will provide access to content from multiple NBCUniversal brands
              </p>
            </div>
            <Button
              displayType="submit"
              title="Create Account"
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

SocialRegistrationScreen.propTypes = {
  setScreen: PropTypes.func.isRequired,
};

export { SocialRegistrationScreen };
