/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/Button';
import { useBedrockRegistration } from 'store';
import { Formik, Field, ErrorMessage } from 'formik';
import { SocialLogin } from 'components/AccountLoginRegistration/SocialLogin';
import { emailSchema } from '../validationSchema';

import globalStyles from '../styles.module.scss';

/**
 * EmailScreen component for handling email input and submission.
 * @param {object} props - The component props.
 * @param {Function} props.setScreen - Function to set the screen state.
 * @param {string} props.entryTitle - The title to display on the screen.
 * @returns {React.ReactElement} The rendered component.
 */
const EmailScreen = ({ setScreen, entryTitle }) => {
  const setEmail = useBedrockRegistration((state) => state.setEmail);
  const { email } = useBedrockRegistration((state) => state.user);
  const searchUserByEmail = useBedrockRegistration((state) => state.searchUserByEmail);

  return (
    <>
      <h2>{entryTitle}</h2>
      <Formik
        initialValues={{ email }}
        validationSchema={emailSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setEmail(values.email);
          const response = await searchUserByEmail(values.email, 'today');
          if (response.success) {
            setScreen('login');
          } else {
            setScreen('password');
          }
          setSubmitting(false);
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          errors,
        }) => (
          <form onSubmit={handleSubmit} data-testid="account-email">
            <label htmlFor="email">Please enter your email</label>
            <Field id="email" type="email" name="email" aria-label="email" className={errors.email ? globalStyles.errorInput : ''} />
            <ErrorMessage name="email" component="div" className={globalStyles.errorMessage} />
            <Button
              displayType="submit"
              title="Continue"
              disabled={isSubmitting}
            />
          </form>
        )}
      </Formik>
      <SocialLogin />
    </>
  );
};

EmailScreen.propTypes = {
  setScreen: PropTypes.func.isRequired,
  entryTitle: PropTypes.string.isRequired,
};

export { EmailScreen };
