import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import getConfig from 'next/config';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';

import { InlineBlinkingLiveDot } from 'components/InlineBlinkingLiveDot';
import { LinkIfHref } from 'components/Link/LinkIfHref';
import { useTranslation } from 'react-i18next';

import { package as PackagePropType } from 'lib/CustomPropTypes';
import {
  STREAM_KEYS,
  getPlayerKeyForPID,
  getPlaymakerMetadata,
  getDefaultMetadata as getDefaultPlaymakerMetadata,
} from 'lib/liveVideo';
import { isVideoLive } from 'lib/videoUtils';
import { VerticalContext, BreakpointContext, FeatureFlagContext } from 'lib/ContextTypes';

import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import {
  LIVE_VIDEO_EMBED_LOGO,
} from 'lib/brandFeatures';
import { useBroadcastScheduleData } from 'lib/Hooks/useBroadcastScheduleData';

import { ERROR_TYPES } from 'components/TVE/Slate';
import WithTVEAuthProvider from 'components/TVE/AuthProvider';
import TempPass from 'components/TVE/TempPass';
import {
  hideProviderSelectAction,
  showProviderSelectAction,
  loadScheduleAction as loadTVEScheduleAction,
} from 'redux/modules/tve';

import { getLogoForStreamKey } from './Logos';
import NDPVideo from './NDPVideo';
import { CoreVideoPlayerEmbed } from './CoreVideoPlayerEmbed';
import { MSNBCMetadata } from './MSNBCMetadata';
import './styles.themed.scss';

const block = 'live-video-embed';

/**
 * LiveVideoEmbed Package
 * @param {object} props React props
 * @param {CurationPackageContent} props.content Package content from BentoAPI
 * @param {string} props.pkgClassName default block level CSS identifier
 * @returns {React.Element} LiveVideoEmbed component
 */
function LiveVideoEmbed(props) {
  const {
    content,
    hasTempPass,
    authenticated,
    pkgClassName,
    providerSelectVisible,
    videoError,
    showProviderSelect,
    hasCurrentShow,
    hideProviderSelect,
    currentTVEProgram,
    loadTVESchedule,
    shouldRenderByBreakpoint: propShouldRenderByBreakpoint,
  } = props;

  const vertical = React.useContext(VerticalContext);
  const { 'msnbc-feature-flag-logo-update': isMsnbcLogoUpdateEnabled } = React.useContext(FeatureFlagContext);
  const { isSorM, isLorXL, hasCheckedViewportSize } = React.useContext(BreakpointContext);
  const [checkTempPass, setCheckTempPass] = React.useState(false);
  const iframeRef = React.useRef(null);
  const { t } = useTranslation();

  const {
    publicRuntimeConfig: { DISABLE_AUTH_TVE_MSNBC: bypassAuth },
  } = getConfig();

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const { isRailLayout, isRailAdjacent, pageType } = (content?.context) || {};

  const { items: [item] } = content;
  const isRail = isRailLayout && !isRailAdjacent;
  const isNews = vertical === 'news';
  const isNoticias = vertical === 'noticias';
  const isCoverPage = pageType === 'cover';

  const packageMetadata = (content?.metadata) || {};
  const playmakerMetadata = getPlaymakerMetadata({
    ...packageMetadata,
    // for LiveVideoEmbed package, playmaker should be 'on' by default
    playmakerToggle: ('playmakerToggle' in packageMetadata)
      ? packageMetadata.playmakerToggle
      : true,
  }, vertical, '2007524');

  const isCoreVideoPlayer = packageMetadata?.playmakerToggle ?? true;

  const isEnabledOnMobileAndTablet = isSorM && (packageMetadata?.displayOnMobileAndTablet ?? true);
  const isEnabledOnNewsCover = isEnabledOnMobileAndTablet && isNews && isCoverPage;
  const isEnabledOnNoticiasCover = isEnabledOnMobileAndTablet && isNoticias && isCoverPage;

  // playmaker metadata
  const playmakerId = (playmakerMetadata?.playmakerIdOverride)
    || (playmakerMetadata?.playmakerId);
  let destinationPageUrl = playmakerMetadata?.destinationPageUrl;
  let dekText = playmakerMetadata?.playmakerDescription;
  let eyebrowText = (playmakerMetadata?.playmakerFlag) || t('Live');

  // video metadata
  const showDek = item?.metadata?.showDek ?? true;
  let isLiveNDPVideo = false;

  const playmakerStreamType = getPlayerKeyForPID(playmakerId);

  const isCustomStream = (packageMetadata?.playmakerId) === 'other';
  const playmakerBrand = isCustomStream
    ? playmakerMetadata?.playmakerBrandOverride
    : playmakerStreamType;

  const branding = isCoreVideoPlayer
    ? playmakerBrand
    : (item?.metadata?.brand) || STREAM_KEYS.NEWS_NOW;

  const displayLogo = getFeatureConfigForBrand(
    LIVE_VIDEO_EMBED_LOGO,
    vertical,
  );

  let Logo = null;

  if (displayLogo) {
    Logo = getLogoForStreamKey(branding);
  }

  const isTodayAllDay = branding === STREAM_KEYS.TODAY_ALL_DAY;
  const isMSNBCTVE = branding === STREAM_KEYS.MSNBC_TVE;
  const isNewsNOW = branding === STREAM_KEYS.NEWS_NOW;
  const isNoticiasAhora = branding === STREAM_KEYS.NOTICIAS_AHORA;

  const { currentShow } = hasCurrentShow ? useBroadcastScheduleData(branding) : '';
  const playmakerTitleOverride = packageMetadata?.playmakerTitle;
  const playmakerTitleDefault = getDefaultPlaymakerMetadata(playmakerId)?.playmakerTitle;
  let headlineText = playmakerTitleOverride // use override if provided from curator
    || currentShow?.title // otherwise use current show title from schedule data
    || playmakerTitleDefault; // or finally fall back to default

  if (!isCoreVideoPlayer) {
    isLiveNDPVideo = item && isVideoLive(item.item);
    dekText = item?.computedValues?.dek;
    eyebrowText = (item?.computedValues?.unibrow?.text) || (isTodayAllDay ? 'Watch' : 'Live');
    headlineText = item?.computedValues?.headline;
    destinationPageUrl = item?.item?.url?.canonical;
  }

  const displayDek = (isCoreVideoPlayer && dekText) || (!isCoreVideoPlayer && showDek && dekText);

  const authWithProvider = authenticated && !hasTempPass;
  const msnbcScheduleDataTitle = currentTVEProgram?.title;
  const msnbcTitle = headlineText || msnbcScheduleDataTitle;
  const isMSNBCLiveVideo = isMSNBCTVE && isCoreVideoPlayer;
  const titleToDisplay = isMSNBCLiveVideo ? msnbcTitle : headlineText;

  const shouldRenderByBreakpoint = propShouldRenderByBreakpoint
    ?? (hasCheckedViewportSize && (isLorXL || isEnabledOnNewsCover || isEnabledOnNoticiasCover));

  React.useEffect(() => {
    // Load vertical schedule
    if (
      shouldRenderByBreakpoint
      && isMSNBCLiveVideo
      // we want to load the schedules if specifically `msnbcScheduleDataTitle` is falsey, meaning
      // it probably hasn't been loaded yet.
      && !msnbcScheduleDataTitle
    ) {
      loadTVESchedule(vertical);
    }
  }, [shouldRenderByBreakpoint, isMSNBCLiveVideo, msnbcScheduleDataTitle]);

  // User not authenticated with mvpd
  React.useEffect(() => {
    if (shouldRenderByBreakpoint && isMSNBCLiveVideo) {
      // authenticated has three states - null, false, & true
      if (authenticated === false && !hasTempPass) {
        // render tempPass component
        setCheckTempPass(true);
      } else if (authenticated && !hasTempPass && providerSelectVisible) {
        // Close MVPD overlay
        hideProviderSelect();
      }
    }
  }, [
    authenticated,
    hasTempPass,
    providerSelectVisible,
    shouldRenderByBreakpoint,
    isMSNBCLiveVideo,
  ]);
  return (
    <section
      className={classNames(
        pkgClassName,
        {
          'live-video-embed--is-rail': isRail,
          'live-video-embed--today-all-day': isTodayAllDay,
          'live-video-embed--msnbc-tve': isMSNBCTVE,
          'live-video-embed--news-now': isNewsNOW,
          'live-video-embed--noticias-ahora': isNoticiasAhora,
          'live-video-embed--enabled-on-news-cover': isEnabledOnNewsCover,
          'live-video-embed--enabled-on-noticias-cover': isEnabledOnNoticiasCover,
          'msnbc-new-logo': isMsnbcLogoUpdateEnabled && branding === STREAM_KEYS.MSNBC_TVE,
        },
      )}
      data-packageid={content.id}
    >
      {(Logo || displayDek) && (
        <LinkIfHref
          href={destinationPageUrl}
          className={`${block}__link ${block}__block`}
        >
          {Logo && (
            <div className={`${block}__block ${block}__logo-container`}>
              {isEnabledOnNewsCover ? (
                <LinkIfHref className={`${block}__logo-link`} href="https://www.nbcnews.com/now">
                  <Logo />
                </LinkIfHref>
              ) : <Logo />}
              {(isRail && !isNewsNOW && !isMSNBCTVE) && (
                <hr className={`${block}__logo-line`} />
              )}
            </div>
          )}
          {displayDek && (
            <div className={`${block}__block ${block}__tagline`}>
              {dekText}
            </div>
          )}
        </LinkIfHref>
      )}
      {isMSNBCLiveVideo && !bypassAuth && (
        <MSNBCMetadata
          hasTempPass={hasTempPass}
          authWithProvider={authWithProvider}
          showProviderSelect={showProviderSelect}
          providerSelectVisible={providerSelectVisible}
        />
      )}
      <div
        ref={ref}
        className={classNames(
          `${block}__block ${block}__aspect-ratio-container`,
          {
            'msnbc-container': isMSNBCLiveVideo && !isRail,
          },
        )}
      >
        {shouldRenderByBreakpoint && (
          // rendering the surrounding markup in order to minimize CLS on >=large breakpoints
          <div className={`${block}__container`}>
            {inView && isMSNBCLiveVideo && !bypassAuth && (
              <>
                <WithTVEAuthProvider
                  iframeRef={iframeRef}
                />
                {videoError !== ERROR_TYPES.BROWSER && (
                  <TempPass
                    checkTempPass={checkTempPass}
                  />
                )}
              </>
            )}
            {isCoreVideoPlayer ? (
              <CoreVideoPlayerEmbed
                playmakerMetadata={playmakerMetadata}
                isMSNBC={isMSNBCTVE}
                isRail={isRail}
                authenticated={authenticated}
                bypassAuth={bypassAuth}
              />
            ) : (
              <NDPVideo item={item} isRail={isRail} />
            )}
          </div>
        )}
      </div>
      <div className={`${block}__block ${block}__flag-and-headline`}>
        <LinkIfHref href={destinationPageUrl} className={`${block}__link`}>
          {isCoreVideoPlayer
            && <InlineBlinkingLiveDot className={`${block}__inline-blinking-live-dot`} />}
          {isCoreVideoPlayer || isLiveNDPVideo
            ? (
              <span
                className={classNames(`${block}__eyebrow`, {
                  [`${block}__eyebrow--today-all-day`]: isTodayAllDay,
                })}
              >
                {eyebrowText}
            &nbsp;/&nbsp;
              </span>
            )
            : null}
          <h2
            className={classNames(`${block}__headline`, {
              [`${block}__headline--today-all-day`]: isTodayAllDay,
            })}
          >
            {titleToDisplay}
          </h2>
        </LinkIfHref>
      </div>
    </section>
  );
}

LiveVideoEmbed.propTypes = {
  authenticated: PropTypes.bool,
  content: PackagePropType.isRequired,
  currentTVEProgram: PropTypes.shape({
    title: PropTypes.string,
  }),
  hasTempPass: PropTypes.bool,
  shouldRenderByBreakpoint: PropTypes.bool,
  pkgClassName: PropTypes.string,
  providerSelectVisible: PropTypes.bool,
  hasCurrentShow: PropTypes.bool,
  showProviderSelect: PropTypes.func,
  hideProviderSelect: PropTypes.func,
  videoError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  loadTVESchedule: PropTypes.func,
};

LiveVideoEmbed.defaultProps = {
  authenticated: null,
  hasTempPass: false,
  hideProviderSelect: Function.prototype,
  shouldRenderByBreakpoint: null,
  pkgClassName: 'pkg live-video-embed',
  providerSelectVisible: false,
  hasCurrentShow: true,
  showProviderSelect: Function.prototype,
  videoError: false,
  currentTVEProgram: {},
  loadTVESchedule: Function.prototype,
};

/**
 *
 * @param {object} root0
 * @param {tveMp4} root0.tve
 * @param {Video} root0.video
 */
const mapStateToProps = ({ tve, video }) => ({
  authenticated: tve.authenticated,
  hasTempPass: tve.hasTempPass,
  providerSelectVisible: tve.providerSelectVisible,
  videoError: video.error,
  currentTVEProgram: {
    title: tve?.epgCurrent?.program?.title,
  },
});

/**
 * Maps dispatch actions to props for the LiveVideoEmbed component.
 *
 * @param {Function} dispatch - The Redux dispatch function
 */
const mapActionsToProps = (dispatch) => ({
  /**
   * Dispatches the action to hide the provider select.
   */
  hideProviderSelect: () => dispatch(hideProviderSelectAction()),
  /**
   * Dispatches the action to show the provider select.
   */
  showProviderSelect: () => dispatch(showProviderSelectAction()),
  /**
   * Dispatches the action to load the TVE schedule for a given vertical.
   *
   * @param {VerticalType} vertical - The vertical for which to load the schedule
   */
  loadTVESchedule: (vertical) => dispatch(loadTVEScheduleAction(vertical)),
});

const LiveVideoEmbedWrapper = connect(mapStateToProps, mapActionsToProps)(LiveVideoEmbed);
export { LiveVideoEmbedWrapper as LiveVideoEmbed };
