import React, { useEffect } from 'react';
import { useBedrockRegistration } from 'store/bedrockRegistration';
import getConfig from 'next/config';

import styles from './styles.module.scss';

const {
  publicRuntimeConfig: {
    TODAY_GOOGLE_CLIENT_ID,
  },
} = getConfig();

/**
 * Renders a Login with Google Button
 */
const GoogleLoginButton = () => {
  const socialLogin = useBedrockRegistration((state) => state.socialLogin);

  /**
   * Handles click on Login with Google Button
   * @param {object} response
   */
  const handleCredentialResponse = async (response) => {
    const idToken = response.credential;

    socialLogin(idToken, 'google').then(() => {
      console.log('successful login with google');
    });
  };

  useEffect(() => {
    // Load Google Identity Services SDK script
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;

    /**
     * Handles Google SDK script load
     */
    script.onload = () => {
      // Initialize the Google Sign-In button
      window.google.accounts.id.initialize({
        client_id: TODAY_GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
      });

      // Render the Google Sign-In button
      window.google.accounts.id.renderButton(
        document.getElementById('google-signin-button'),
        {
          theme: 'outline',
          size: 'large',
        },
      );
    };

    document.body.appendChild(script);
  }, []);

  return (
    <div>
      <div
        id="google-signin-button"
        className={styles.googleLogin}
        role="button"
        aria-label="Sign in with Google"
        tabIndex={0}
      />
    </div>
  );
};

export { GoogleLoginButton };
