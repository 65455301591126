import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Link from 'components/Link';
import TeasePicture from 'components/TeasePicture';
import ContentTimestamp from 'components/ContentTimestamp';
import { Unibrow } from 'components/Unibrow';
import { TypeIconInline } from 'components/TypeIconInline';
import { ShoppingCartIcon } from 'components/Icon/Shop';

import { content as contentPropType } from 'lib/CustomPropTypes';
import { formatDuration } from 'lib/DateTime';
import { getIconForArticleType } from 'lib/iconUtils';
import { getTeasePicture } from 'lib/teaseUtils';
import { getDeepLink } from 'lib/deepLinking';
import { BreakpointContext, VerticalContext } from 'lib/ContextTypes';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { PKG_SAVE } from 'lib/brandFeatures';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import { Save } from 'components/SocialShareMenu/Save';
import styles from './styles.module.scss';

const TEASE_SIZES = {
  one: 'one',
  two: 'two',
};

const TEASE_SIZE_TO_IMAGE_SIZES = {
  [TEASE_SIZES.one]: {
    s: AIMS_FLAVORS.FOCAL_240x240,
  },
  [TEASE_SIZES.two]: {
    small: {
      s: AIMS_FLAVORS.FOCAL_260x130,
    },
    large: {
      s: AIMS_FLAVORS.FOCAL_260x130,
      m: AIMS_FLAVORS.FOCAL_360x180,
    },
  },
};

const TEASE_SIZE_TO_ASPECT_RATIO = {
  [TEASE_SIZES.one]: 'ASPECT_1X1',
  [TEASE_SIZES.two]: 'ASPECT_2X1',
};

// TODO: Update this image map to urls without hashes when the CMS supports that functionality
/* eslint-disable max-len */
const FALLBACK_IMG_URL_MAP = {
  [TEASE_SIZES.two]: {
    better:
    'https://media4.s-nbcnews.com/i/newscms/2019_23/2888161/2x1_better_fallback_image_215abc36bbb66c1ffd105ebd4f190283.jpg',
    globalcitizen:
    'https://media1.s-nbcnews.com/i/newscms/2019_23/2888166/2x1_global-citizen_fallback_image_215abc36bbb66c1ffd105ebd4f190283.jpg',
    mach: 'https://media3.s-nbcnews.com/i/newscms/2019_23/2888181/2x1_mach_fallback_image_215abc36bbb66c1ffd105ebd4f190283.jpg',
    msnbc:
      'https://media2.s-nbcnews.com/i/newscms/2019_23/2888186/2x1_msnbc_fallback_image_215abc36bbb66c1ffd105ebd4f190283.jpg',
    news: 'https://media1.s-nbcnews.com/i/newscms/2019_23/2888216/2x1_nbcnews_fallback_image_0_cbf07ab7dd92887d74bd5e4ed1bde2e5.jpg',
    select:
    'https://media4.s-nbcnews.com/i/newscms/2021_38/3507110/fallback_2_1_select_b61f24cd574be68f4e1d2ac299748364.png',
    think:
      'https://media2.s-nbcnews.com/i/newscms/2019_23/2888206/2x1_think_fallback_image_0_a5e44c6d5a0a2527b7c92c966f3fb003.jpg',
    today:
      'https://media-cldnry.s-nbcnews.com/image/upload/rockcms/2024-02/sunrise-846ed5.svg',
  },
  [TEASE_SIZES.one]: {
    better:
      'https://media3.s-nbcnews.com/i/newscms/2021_38/3507103/fallback_1_1_better_b61f24cd574be68f4e1d2ac299748364.png',
    globalcitizen:
      'https://media3.s-nbcnews.com/i/newscms/2021_38/3507104/fallback_1_1_globalcitizen_b61f24cd574be68f4e1d2ac299748364.png',
    msnbc:
      'https://media1.s-nbcnews.com/i/newscms/2021_38/3507106/fallback_1_1_msnbc_b61f24cd574be68f4e1d2ac299748364.png',
    news: 'https://media2.s-nbcnews.com/i/newscms/2021_38/3507107/fallback_1_1_nbc_b61f24cd574be68f4e1d2ac299748364.png',
    select:
      'https://media4.s-nbcnews.com/i/newscms/2021_38/3507108/fallback_1_1_select_b61f24cd574be68f4e1d2ac299748364.png',
    think:
      'https://media3.s-nbcnews.com/i/newscms/2021_38/3507109/fallback_1_1_think_b61f24cd574be68f4e1d2ac299748364.png',
    today:
    'https://media-cldnry.s-nbcnews.com/image/upload/rockcms/2024-02/sunrise-846ed5.svg',
  },
};
  /* eslint-enable max-len */

function getHeadline({
  headlineText, url, type, target, deepLink, productId, showEyebrow,
}) {
  return (
    <h3
      className={classNames(styles.baconCardsWidthByOneHeader, {
        [styles.noEyebrow]: !showEyebrow,
      })}
      data-testid="x-by-one__headline__header"
    >
      <Link
        to={url}
        data-product-id={productId}
        className={styles.baconCardsWidthByOneHeaderLink}
        data-testid="x-by-one__headline__link"
        target={target}
      >
        <span className={styles.baconCardsWidthByOneHeaderLinkIcon}>
          <TypeIconInline type={type} />
        </span>
        {deepLink && (
          <span className={styles.baconCardsWidthByOneHeaderDeepLinkEpisode}>
            {deepLink.episode}
          </span>
        )}
        {headlineText}
      </Link>
    </h3>
  );
}

function getTeaseCategory({
  computedValues, item, type, deepLink,
}) {
  const brow = computedValues?.unibrow ? { ...computedValues.unibrow } : {};

  // Link to canonical
  brow.url = { ...brow.url, primary: computedValues.url };

  if (type === 'slideshow') {
    // Show slide count
    const slideCount = item?.slides?.length;
    brow.text = `${slideCount} IMAGES`;
  }

  if (type === 'video') {
    const { t } = useTranslation();
    brow.text = item?.duration ? formatDuration(item.duration) : t('Watch');
  }

  const iconClasses = `${styles.baconCardsWidthByOneIcon} icon ${getIconForArticleType(
    type,
  )}`;

  return (
    <div
      className={`${styles.baconCardsWidthByOneTeaseCategory} x-by-one__tease-category`}
      data-testid="x-by-one__tease-category"
    >
      <Unibrow
        className={classNames(styles.baconCardsWidthByOneEyebrow,
          { [styles.baconCardsWidthByOneEyebrowVideo]: type === 'video' },
          { [styles.baconCardsWidthByOneEyebrowSlideshow]: type === 'slideshow' },
          type,
          { [styles.baconCardsWidthByOneEyebrowDeepLink]: deepLink })}
        unibrow={{ ...brow, url: null }}
        testId="x-by-one__tease-category__unibrow"
      />
      <div className={styles.baconCardsWidthByOneIconContainer}>
        <span
          className={iconClasses}
          data-testid="x-by-one__tease-category__type-icon"
        />
        <span className={styles.baconCardsWidthByOneIconBackground} />
      </div>
    </div>
  );
}

function getInfo(item, unibrow, target, options) {
  const { dateCreated, datePublished } = item;
  const { showEyebrow, showTimestamp } = options;

  const fontStyles = styles.baconCardsWidthByOneFontStyle;
  const timestampStyles = styles.baconCardsWidthByOneTimestampStyle;

  const delimiter = (
    <span
      className={classNames(fontStyles, timestampStyles)}
      data-testid="x-by-one__info__delimiter"
    >
      &nbsp;/&nbsp;
    </span>
  );

  return (
    <div>
      {(showEyebrow && unibrow.text) && (
        <Unibrow
          className={classNames(
            fontStyles,
            styles.baconCardsWidthByOneUnibrowSectionName,
          )}
          unibrow={unibrow}
          testId="x-by-one__info__unibrow"
          target={target}
        />
      )}
      {showEyebrow && showTimestamp && delimiter}
      {showTimestamp && (
        <ContentTimestamp
          dateCreated={dateCreated}
          datePublished={datePublished}
          dateOnly
          testId="x-by-one__info__timestamp"
          styles={classNames(fontStyles, timestampStyles)}
        />
      )}
    </div>
  );
}

function seasonDate(deepLink) {
  return (
    <span className={styles.xByOneDeepLinkSeasonDate}>
      <span className={styles.xByOneDeepLinkSeason}>{deepLink.season}</span>
      <ContentTimestamp
        dateLabel="Air Date"
        dateOnly
        datePublished={deepLink.datePublished}
      />
    </span>
  );
}

export const WidthByOne = (props) => {
  const {
    trackBacon,
    card: {
      computedValues = {},
      id,
      isLazyLoaded = true,
      item: canonicalItem,
      type,
    } = {},
    openInNewTab,
    pkgType,
    showEyebrow,
    showTimestamp,
    size,
    width,
    title,
    variant,
  } = props;

  const item = canonicalItem || {};
  const {
    headline: headlineText = '',
    unibrow = {},
  } = computedValues;
  let { url } = computedValues;

  const { isS: isMobile = false } = useContext(BreakpointContext);
  const vertical = useContext(VerticalContext);

  const deepLink = getDeepLink({ item, type }, pkgType, isMobile);
  const target = (deepLink && '_blank') || (openInNewTab ? '_blank' : '_self');
  url = deepLink ? deepLink.url : url;

  const sizes = variant
    ? TEASE_SIZE_TO_IMAGE_SIZES[size][variant]
    : TEASE_SIZE_TO_IMAGE_SIZES[size];

  const height = width / (size === 'two' ? 2 : 1);

  const computedTeasePicture = getTeasePicture(
    computedValues,
    FALLBACK_IMG_URL_MAP[size],
    vertical,
    TEASE_SIZE_TO_ASPECT_RATIO[size],
  );

  const showSave = getFeatureConfigForBrand(PKG_SAVE, vertical);

  return (
    <div
      className={styles.baconCardsWidthByOne}
      data-testid="x-by-one__container"
      data-contentid={id}
    >
      <div className={styles.baconCardsWidthByOneTeasePictureLinkWrapper}>
        <div>
          <Link
            onClick={() => trackBacon({
              action: 'cardClick',
              title,
              id,
              headlineText,
            })}
            to={url}
            data-product-id={id}
            className={classNames(styles.baconCardsWidthByOneTeasePictureLink, {
              [styles.baconCardsWidthByOneTeasePictureLinkDeepLink]: deepLink,
            })}
            data-testid="x-by-one__tease-picture__link"
            target={target}
          >
            {computedValues.isShoppable
              ? <ShoppingCartIcon />
              : null }
            <TeasePicture
              computedValues={computedTeasePicture}
              height={height}
              lazyload={isLazyLoaded}
              responsiveFlavors={sizes}
              type={type}
              width={width}
            />
            {getIconForArticleType(type)
              ? getTeaseCategory({
                computedValues, item, type, deepLink, target,
              }) : null}
          </Link>
        </div>
        {showSave ? (
          <Save
            contentId={id || item.id}
            contentType={type || item.type}
            options={{ isThumbnail: true, showCTA: true }}
          />
        ) : null}
      </div>
      {getHeadline({
        headlineText, url, type, target, deepLink, productId: id, showEyebrow,
      })}
      {deepLink
        ? seasonDate(deepLink)
        : getInfo(item, unibrow, target, {
          showEyebrow,
          showTimestamp,
          vertical,
        })}
    </div>
  );
};

WidthByOne.propTypes = {
  title: PropTypes.string,
  trackBacon: PropTypes.func,
  card: contentPropType,
  openInNewTab: PropTypes.bool,
  pkgType: PropTypes.string,
  showEyebrow: PropTypes.bool,
  showTimestamp: PropTypes.bool,
  size: PropTypes.oneOf([TEASE_SIZES.one, TEASE_SIZES.two]),
  variant: PropTypes.string,
  width: PropTypes.number.isRequired,
};

WidthByOne.defaultProps = {
  title: '',
  trackBacon: () => {},
  card: {},
  openInNewTab: false,
  pkgType: null,
  showEyebrow: true,
  showTimestamp: false,
  size: TEASE_SIZES.two,
  variant: undefined,
};
