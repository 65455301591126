import React, { useEffect, useRef, useState } from 'react';

import { AccountLoginRegistration } from 'components/AccountLoginRegistration';
import { useBedrockRegistration } from 'store';
import styles from './styles.module.scss';


/**
 * LoginScreen component for handling password input and submission.
 * @returns {React.ReactElement} The rendered component.
 */
const Overlay = () => {
  const showAuthenticationModal = useBedrockRegistration((state) => state.showAuthenticationModal);
  const setAuthenticationModal = useBedrockRegistration((state) => state.setAuthenticationModal);
  const [lastFocus, setLastFocus] = useState({});
  const closeBtnRef = useRef();

  /**
   * Resets the AuthenticationModal state and attributes of body and content elements.
   */
  const handleCloseModal = () => {
    setAuthenticationModal(false);
    document.getElementById('content').inert = false;
    document.body.style.overflow = 'auto';
    lastFocus.focus();
  };

  useEffect(() => {
    if (showAuthenticationModal) {
      setLastFocus(document.activeElement);
      const contentEl = document.getElementById('content');
      if (contentEl) contentEl.inert = true;
      document.body.style.overflow = 'hidden';
      closeBtnRef.current.focus();
    }
  }, [showAuthenticationModal]);

  return (
    showAuthenticationModal ? (
      <dialog
        open={showAuthenticationModal}
        className={styles.dialog}
        data-testid="account-overlay"
      >
        <div className={styles.content}>
          <button
            className={styles.close}
            ref={closeBtnRef}
            type="button"
            onClick={handleCloseModal}
            aria-label="Close"
          >
              X
          </button>
          <AccountLoginRegistration />
        </div>
      </dialog>
    ) : null
  );
};


export { Overlay };
